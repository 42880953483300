import axios from "axios";
import CryptoJS from "crypto-js";

// initial state
const state = () => ({
  userInfo: null,
  userToken: null,
  userIsLoggedIn: false,
  isToastingNotification: false,
  isGlobalLoading: false,
  isLoggin: false,
  isLogginMsg: "",
});

// getters
const getters = {
  userInfo: (state) => state.userInfo,
  userToken: (state) => state.userToken,
  isToastingNotification: (state) => state.isToastingNotification,
  isGlobalLoading: (state) => state.isGlobalLoading,
  isLoggin: (state) => state.isLoggin,
  isLogginMsg: (state) => state.isLogginMsg,
};

// actions
const actions = {
  async encryptId({ rootGetters }, data) {
    if (data && data !== "" && data !== 0 && data !== "0") {
      var iv = CryptoJS.enc.Hex.parse("be410fea41df7162a679875ec131cf2c");
      var encrypt = CryptoJS.AES.encrypt(
        data.toString(),
        rootGetters.userToken,
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
      return encrypt;
    } else {
      return 0;
    }
  },
  async decryptId({ rootGetters }, data) {
    if (data && data !== "" && data !== 0 && data !== "0") {
      var iv = CryptoJS.enc.Hex.parse("be410fea41df7162a679875ec131cf2c");
      var decrypt = CryptoJS.AES.decrypt(data, rootGetters.userToken, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }).toString(CryptoJS.enc.Utf8);
      return decrypt;
    } else {
      return 0;
    }
  },

  async login({ commit, dispatch, rootGetters, rootState }, { payload, that }) {
    await dispatch("setIsGlobalLoading", true);
    commit("SET_IS_LOGGIN", true);
    var config = {
      method: "post",
      url: process.env.VUE_APP_API_ORISIS + "Account/Login",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    await axios(config)
      .then(async function (response) {
        commit("SET_USER_INFO", response.data);
        if (response.data?.token) {
          commit("SET_USER_TOKEN", response.data.token);
          commit("SET_USER_IS_LOGGED_IN", true);
          commit("SET_IS_LOGGIN", false);
          commit("SET_IS_LOGGIN_MSG", "");
          commit("SET_WORKSPACES_LIST_BY_USER", response.data.workspaces);
          if (response.data.workspaces.length === 1) {
              dispatch("setWorkspaceSelected", {
                workspaceIdSelected: response.data.workspaces[0].id});
              that.$router.push("/")
          } else {
            that.$router.push("/workspace");
          }
          setTimeout(async () => {
            await dispatch("setIsGlobalLoading", false);
          }, 200);
        } else {
          commit("SET_USER_TOKEN", "");
          commit("SET_USER_IS_LOGGED_IN", false);
          commit("SET_IS_LOGGIN", false);
          commit(
            "SET_IS_LOGGIN_MSG",
            "Identifiant ou mot de passe incorrecte, veuillez recommencer."
          );
          await dispatch("setIsGlobalLoading", false);
        }
      })
      .catch(async (err) => {
        commit("SET_USER_TOKEN", "");
        commit("SET_USER_IS_LOGGED_IN", false);
        commit("SET_IS_LOGGIN", false);
        commit(
          "SET_IS_LOGGIN_MSG",
          "Identifiant ou mot de passe incorrecte, veuillez recommencer."
        );
        await dispatch("setIsGlobalLoading", false);
      });
  },
  async forgotPassword({ commit, dispatch, rootState }, { payload, that }) {
    var config = {
      method: "post",
      url: process.env.VUE_APP_API_ORISIS + "Account/ForgotPassword",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    await axios(config).then(function (response) {
      that.$router.push({ name: "login" });
      that.$nextTick(() => {
        that.$bvToast.toast(
          "Veuillez vérifier votre boite e-mail pour continuer la procédure.",
          {
            title: `Ré-initialisation de mot de passe initiée`,
            variant: "success",
            solid: true,
          }
        );
      });
    });
  },
  async resetPassword({ commit, dispatch, rootState }, { payload, that }) {
    var config = {
      method: "post",
      url: process.env.VUE_APP_API_ORISIS + "Account/ResetPassword",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    await dispatch("setIsGlobalLoading", true);
    await axios(config).then((response) => {
      if (!response.data) {
        that.$bvToast.toast(
          "Le délai de ré-initialisation a expiré, veuillez renouveler votre demande.",
          {
            title: `Réinitialisation impossible`,
            variant: "danger",
            solid: true,
          }
        );
      } else {
        commit("SET_USER_INFO", response.data);
        if (response.data?.token) {
          commit("SET_USER_TOKEN", response.data.token);
          commit("SET_USER_IS_LOGGED_IN", true);
          // dispatch("setWorkspaceSelected", {
          //   workspaceIdSelected: response.data.workspaces[0].id,
          // }).then(async () => {
          //   await dispatch(
          //     "fetchInstitutionsList",
          //     response.data.workspaces[0].id
          //   );
          // });
          commit("SET_WORKSPACES_LIST_BY_USER", response.data.workspaces);
          that.$router.push("/workspace");
          dispatch("setIsGlobalLoading", false);
          that.$nextTick(() => {
            that.$bvToast.toast(
              "Votre nouveau mot de passe a été correctement enregistré.",
              {
                title: `Authentification réussie`,
                variant: "success",
                solid: true,
              }
            );
          });
        } else {
          commit("SET_USER_TOKEN", "");
          commit("SET_USER_IS_LOGGED_IN", false);
        }
      }
    });
  },
  async changePassword({ commit, dispatch, rootState }, { payload, that }) {
    var config = {
      method: "post",
      url: process.env.VUE_APP_API_ORISIS + "Account/ChangePassword",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    await dispatch("setIsGlobalLoading", true);
    await axios(config).then((response) => {
      if (!response.data) {
        that.$bvToast.toast(
          "Veuillez ré-essayer avec un nouveau mot de passe.",
          {
            title: `Changement de mot passe impossible`,
            variant: "danger",
            solid: true,
          }
        );
      } else {
        commit("SET_USER_INFO", response.data);
        if (response.data?.token) {
          commit("SET_USER_TOKEN", response.data.token);
          commit("SET_USER_IS_LOGGED_IN", true);
          dispatch("setWorkspaceSelected", {
            workspaceIdSelected: response.data.workspaces[0].id
          }).then(async () => {
            await dispatch(
              "fetchInstitutionsList",
              response.data.workspaces[0].id
            );
          });
          commit("SET_WORKSPACES_LIST_BY_USER", response.data.workspaces);
          that.$router.push("/");
          that.$nextTick(() => {
            that.$bvToast.toast(
              "Votre nouveau mot de passe a été correctement enregistré.",
              {
                title: `Authentification réussie`,
                variant: "success",
                solid: true,
              }
            );
          });
        } else {
          commit("SET_USER_TOKEN", "");
          commit("SET_USER_IS_LOGGED_IN", false);
        }
      }
    });
  },

  logOut(state, payload) {
    state.isLogginMsg = "";
    state.userInfo = null;
    state.userToken = null;
    state.userIsLoggedIn = false;
    // localStorage.setItem('userInfo', null)
    // localStorage.setItem('userToken', null)
    // localStorage.setItem('userIsLoggedIn', false)
    localStorage.clear();
  },

  async initializeStore({ commit, dispatch, rootGetters, rootState }) {
    // On initialize les listes standard
    await dispatch("fetchUsersList");
  },

  async setIsToastingNotification({ commit }, statut) {
    await commit("SET_IS_TOASTING_NOTIFICATION", statut);
  },

  async setIsGlobalLoading({ commit }, statut) {
    await commit("SET_IS_GLOBAL_LOADING", statut);
  },

  async setIsLoggin({ commit }, statut) {
    await commit("SET_IS_LOGGIN", statut);
  },

  async setIsLogginMsg({ commit }, msg) {
    await commit("SET_IS_LOGGIN_MSG", msg);
  },
};

// mutations
const mutations = {
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  },
  SET_USER_TOKEN: (state, userToken) => {
    state.userToken = userToken;
    localStorage.setItem("userToken", userToken);
  },
  SET_USER_IS_LOGGED_IN: (state, userIsLoggedIn) => {
    state.userIsLoggedIn = userIsLoggedIn;
    localStorage.setItem("userIsLoggedIn", userIsLoggedIn);
  },
  SET_IS_TOASTING_NOTIFICATION(state, payload) {
    state.isToastingNotification = payload;
  },
  SET_IS_GLOBAL_LOADING(state, payload) {
    state.isGlobalLoading = payload;
  },
  SET_IS_LOGGIN(state, payload) {
    state.isLoggin = payload;
  },
  SET_IS_LOGGIN_MSG(state, payload) {
    state.isLogginMsg = payload;
  },
}; 

export default {
  state,
  getters,
  actions,
  mutations,
};
