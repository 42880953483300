import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { AccessMethodTypeResponseDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IAccessMethodTypeState } from "./states";

export enum AccessMethodTypeMutations {
    SET_ACCESS_METHOD_TYPES_LIST = "SET_ACCESS_METHOD_TYPES_LIST",
    SET_ACCESS_METHOD_TYPES_LIST_ARCHIVED = "SET_ACCESS_METHOD_TYPES_LIST_ARCHIVED",
    SET_ACCESS_METHOD_TYPE = "SET_ACCESS_METHOD_TYPE",

    // COMMON
    SET_IS_LOADING_ACCESS_METHOD_TYPES_LIST= "SET_IS_LOADING_ACCESS_METHOD_TYPES_LIST",
    SET_IS_LOADING_ACCESS_METHOD_TYPES_LIST_ARCHIVED= "SET_IS_LOADING_ACCESS_METHOD_TYPES_LIST_ARCHIVED",
    
    SET_IS_LOADING_ACCESS_METHOD_TYPE= "SET_IS_LOADING_ACCESS_METHOD_TYPE",
    SET_IS_CREATING_ACCESS_METHOD_TYPE = "SET_IS_CREATING_ACCESS_METHOD_TYPE",
    SET_IS_UPDATING_ACCESS_METHOD_TYPE= "SET_IS_UPDATING_ACCESS_METHOD_TYPE",
    SET_IS_DELETING_ACCESS_METHOD_TYPE = "SET_IS_DELETING_ACCESS_METHOD_TYPE",
    SET_REQUEST_STATE_ACCESS_METHOD_TYPE = "SET_REQUEST_STATE_ACCESS_METHOD_TYPE",
    SET_IS_OPEN_SIDEBAR_ACCESS_METHOD_TYPE = "SET_IS_OPEN_SIDEBAR_ACCESS_METHOD_TYPE",
    SET_IS_ARCHIVING_ACCESS_METHOD_TYPE = "SET_IS_ARCHIVING_ACCESS_METHOD_TYPE"
}

export const mutations: MutationTree<IAccessMethodTypeState> = {
    [AccessMethodTypeMutations.SET_ACCESS_METHOD_TYPES_LIST]: (state, payload: AccessMethodTypeResponseDto[]) => {
        state.accessMethodTypesList = payload;
    },
    [AccessMethodTypeMutations.SET_ACCESS_METHOD_TYPES_LIST_ARCHIVED]: (state, payload: AccessMethodTypeResponseDto[]) => {
        state.accessMethodTypesListArchived = payload;
    },
    [AccessMethodTypeMutations.SET_ACCESS_METHOD_TYPE]: (state, payload: AccessMethodTypeResponseDto) => {
        state.accessMethodType = payload;
    },


    //COMMON
    [AccessMethodTypeMutations.SET_IS_LOADING_ACCESS_METHOD_TYPES_LIST]: (state, payload: boolean) => {
        state.isLoadingAccessMethodTypesList = payload;
    },
    [AccessMethodTypeMutations.SET_IS_LOADING_ACCESS_METHOD_TYPES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingAccessMethodTypesListArchived = payload;
    },
    [AccessMethodTypeMutations.SET_IS_LOADING_ACCESS_METHOD_TYPE]: (state, payload: boolean) => {
        state.isLoadingAccessMethodType = payload;
    },
    [AccessMethodTypeMutations.SET_IS_CREATING_ACCESS_METHOD_TYPE]: (state, payload: boolean) => {
        state.isCreatingAccessMethodType = payload;
    },
    [AccessMethodTypeMutations.SET_IS_UPDATING_ACCESS_METHOD_TYPE]: (state, payload: boolean) => {
        state.isUpdatingAccessMethodType = payload;
    },
    [AccessMethodTypeMutations.SET_IS_DELETING_ACCESS_METHOD_TYPE]: (state, payload: boolean) => {
        state.isDeletingAccessMethodType = payload;
    },
    [AccessMethodTypeMutations.SET_REQUEST_STATE_ACCESS_METHOD_TYPE]: (state, payload: IRequestState) => {
        state.requestStateAccessMethodType = payload;
    },
    [AccessMethodTypeMutations.SET_IS_OPEN_SIDEBAR_ACCESS_METHOD_TYPE]: (state, payload: boolean) => {
        state.isOpenSidebarAccessMethodType = payload;
    },
    [AccessMethodTypeMutations.SET_IS_ARCHIVING_ACCESS_METHOD_TYPE]: (state, payload: boolean) => {
        state.isArchivingAccessMethodType = payload;
    },
}