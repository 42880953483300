import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { MissionResponseDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../RootState'
import { IMissionState } from './states'


export const getters: GetterTree<IMissionState, RootState> = {
  missionsList: (state): MissionResponseDto[] => state.missionsList,
  commonMissionsList: (state): MissionResponseDto[] => state.commonMissionsList,
  missionsListArchived: (state): MissionResponseDto[] => state.missionsListArchived,
  commonMissionsListArchived: (state): MissionResponseDto[] => state.commonMissionsListArchived,
  mission     : (state): MissionResponseDto => state.mission,

  // COMMON
  isLoadingMissionsList   : (state): boolean => state.isLoadingMissionsList,
  isLoadingCommonMissionsList   : (state): boolean => state.isLoadingCommonMissionsList,
  isLoadingMissionsListArchived   : (state): boolean => state.isLoadingMissionsListArchived,
  isLoadingCommonMissionsListArchived   : (state): boolean => state.isLoadingCommonMissionsListArchived,
  isLoadingMission   : (state): boolean => state.isLoadingMission,
  isCreatingMission  : (state): boolean => state.isCreatingMission,
  isUpdatingMission  : (state): boolean => state.isUpdatingMission,
  isDeletingMission  : (state): boolean => state.isDeletingMission,
  requestStateMission: (state): IRequestState => state.requestStateMission,
  requestStateCommonMission: (state): IRequestState => state.requestStateCommonMission,
  isOpenSidebarMission : (state): boolean => state.isOpenSidebarMission,
  isArchivingMission : (state): boolean => state.isArchivingMission,
}