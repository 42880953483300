import { UserRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import { WorkspaceMutations } from "../../account/workspace/mutations";
import RootState from "../../RootState";
import { UserMutations } from "./mutations";
import { IUserState } from "./states";

export const actions: ActionTree<IUserState, RootState> = {
  /**
   * It fetches the list of users from the API and stores it in the state.
   */
  async fetchUsersList({ commit, rootGetters }): Promise<void> {
    commit(UserMutations.SET_IS_LOADING_USERS_LIST, true);
    axios
      .get(
        process.env.VUE_APP_API_ORISIS + "Account/User/GetUsers",
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        }
      )
      .then((res) => {
        commit(UserMutations.SET_USERS_LIST, res.data);
        commit(UserMutations.SET_REQUEST_STATE_USER, res);
        commit(UserMutations.SET_IS_LOADING_USERS_LIST, false);
      })
      .catch((err) => {
        console.error(err);
        commit(UserMutations.SET_REQUEST_STATE_USER, err);
        commit(UserMutations.SET_IS_LOADING_USERS_LIST, false);
      });
  },
  async fetchUsersListArchived({ commit, rootGetters }): Promise<void> {
    commit(UserMutations.SET_IS_LOADING_USERS_LIST_ARCHIVED, true);
    axios
      .get(
        process.env.VUE_APP_API_ORISIS + "Account/User/GetUsersArchived",
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        }
      )
      .then((res) => {
        commit(UserMutations.SET_USERS_LIST_ARCHIVED, res.data);
        commit(UserMutations.SET_REQUEST_STATE_USER, res);
        commit(UserMutations.SET_IS_LOADING_USERS_LIST_ARCHIVED, false);
      })
      .catch((err) => {
        console.error(err);
        commit(UserMutations.SET_REQUEST_STATE_USER, err);
        commit(UserMutations.SET_IS_LOADING_USERS_LIST_ARCHIVED, false);
      });
  },

  /**
   * Fetch a user from the API
   * @param  - userId: The id of the user you want to fetch.
   * @param {string} userId - The id of the user you want to fetch.
   */
  async fetchUser(
    { commit, rootGetters },
    userId: string
  ): Promise<UserRequestDto> {
    return new Promise((resolve, reject) => {
      commit(UserMutations.SET_IS_LOADING_USER, true);
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Account/User/GetUserById", {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          params: {
            userId: userId,
          },
        })
        .then((res) => {
          commit(UserMutations.SET_USER, res.data);
          commit(UserMutations.SET_REQUEST_STATE_USER, res);
          commit(UserMutations.SET_IS_LOADING_USER, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(UserMutations.SET_REQUEST_STATE_USER, err);
          commit(UserMutations.SET_IS_LOADING_USER, false);
        });
    });
  },

  async fetchUserArchived(
    { commit, rootGetters },
    userId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(UserMutations.SET_IS_LOADING_USER, true);
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + "Account/User/GetUserArchivedById",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              userId: userId,
            },
          }
        )
        .then((res) => {
          commit(UserMutations.SET_USER, res.data);
          commit(UserMutations.SET_REQUEST_STATE_USER, res);
          commit(UserMutations.SET_IS_LOADING_USER, false);

          resolve({
            ...res.data,
            workspaceId: rootGetters.workspaceSelected.id,
          });
        })
        .catch((err) => {
          console.error(err);
          commit(UserMutations.SET_REQUEST_STATE_USER, err);
          commit(UserMutations.SET_IS_LOADING_USER, false);
        });
    });
  },

  /**
   * Create a user and add it to the users list
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {UserRequestDto} user - The user object that will be sent to the API.
   */
  async createUser(
    { commit, rootGetters },
    user: UserRequestDto
  ): Promise<void> {
    commit(UserMutations.SET_IS_CREATING_USER, true);
    axios
      .post(process.env.VUE_APP_API_ORISIS + "Account/User/CreateUser", user, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`,
        },
      })
      .then((res) => {
        rootGetters.usersList.unshift(res.data);
        commit(UserMutations.SET_USERS_LIST, rootGetters.usersList);
        commit(UserMutations.SET_REQUEST_STATE_USER, res);
        commit(UserMutations.SET_IS_CREATING_USER, false);
      })
      .catch((err) => {
        console.error(err);
        commit(UserMutations.SET_REQUEST_STATE_USER, err);
        commit(UserMutations.SET_IS_CREATING_USER, false);
      });
  },

  /**
   * Update a user
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {UserRequestDto} user - The user object that will be updated.
   */
  async updateUser(
    { commit, rootGetters, dispatch },
    user: UserRequestDto
  ): Promise<void> {
    commit(UserMutations.SET_IS_UPDATING_USER, true);
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + "Account/User/UpdateUser",
        user,

        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        }
      )
      .then((res) => {
        rootGetters.usersList.splice(
          rootGetters.usersList.findIndex((elem) => elem.id == user.id),
          1,
          user
        );
        commit(UserMutations.SET_USERS_LIST, rootGetters.usersList);
        commit(UserMutations.SET_REQUEST_STATE_USER, res);
        commit(UserMutations.SET_IS_UPDATING_USER, false);
      })
      .catch((err) => {
        console.error(err);
        commit(UserMutations.SET_REQUEST_STATE_USER, err);
        commit(UserMutations.SET_IS_UPDATING_USER, false);
      });
  },
  async updatePassword(
    { commit, rootGetters },
     dataUpdatePassword
  ): Promise<void> {
    axios
      .post(
        process.env.VUE_APP_API_ORISIS + "Account/ChangePassword",
        dataUpdatePassword,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        }
      )
      .then((res) => {
        commit(UserMutations.SET_REQUEST_STATE_USER, res);
      })
      .catch((err) => {
        console.error(err);
        commit(UserMutations.SET_REQUEST_STATE_USER, err);
      });
  },
  async updateUserWorkspace(
    { commit, rootGetters },
    { userId, workspaceIds }
  ): Promise<void> {
    commit(UserMutations.SET_IS_UPDATING_USER, true);
    axios
      .put(
        process.env.VUE_APP_API_ORISIS +
          "Account/User/UpdateUserWorkspace",
          workspaceIds,

        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          params: {
            userId: userId,
          },
        }
      )
      .then((res) => {
        commit(UserMutations.SET_REQUEST_STATE_USER, res);
        commit(UserMutations.SET_IS_UPDATING_USER, false);
      })
      .catch((err) => {
        console.error(err);
        commit(UserMutations.SET_REQUEST_STATE_USER, err);
        commit(UserMutations.SET_IS_UPDATING_USER, false);
      });
  },

  async activeUser({ commit, rootGetters }, { userIds }): Promise<void> {
    commit(UserMutations.SET_IS_UPDATING_USER, true);
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + "Account/User/ActiveUserAccounts",
        userIds,

        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        }
      )
      .then((res) => {
        commit(UserMutations.SET_REQUEST_STATE_USER, res);
        commit(UserMutations.SET_IS_UPDATING_USER, false);
      })
      .catch((err) => {
        console.error(err);
        commit(UserMutations.SET_REQUEST_STATE_USER, err);
        commit(UserMutations.SET_IS_UPDATING_USER, false);
      });
  },

  /**
   * Delete a user from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} userId - The id of the user to delete.
   */
  async deleteUser({ commit, rootGetters }, userId: string): Promise<void> {
    commit(UserMutations.SET_IS_DELETING_USER, true);
    axios
      .delete(process.env.VUE_APP_API_ORISIS + "Account/User/Delete", {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`,
        },
        params: {
          userId: userId,
        },
      })
      .then((res) => {
        rootGetters.usersListArchived.splice(
          rootGetters.usersListArchived.findIndex((elem) => elem.id == userId),
          1
        );

        commit(UserMutations.SET_USERS_LIST, rootGetters.usersList);
        commit(UserMutations.SET_REQUEST_STATE_USER, res);
        commit(UserMutations.SET_IS_DELETING_USER, false);
      })
      .catch((err) => {
        console.error(err);
        commit(UserMutations.SET_REQUEST_STATE_USER, err);
        commit(UserMutations.SET_IS_DELETING_USER, false);
      });
  },

  /**
   * Delete a user from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} userId - The id of the user to delete.
   */
  async archiveUser({ commit, rootGetters }, userId: string): Promise<void> {
    commit(UserMutations.SET_IS_ARCHIVING_USER, true);
    axios
      .delete(process.env.VUE_APP_API_ORISIS + "Account/User/Archive", {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`,
        },
        data: [userId],
      })
      .then((res) => {
        rootGetters.usersListArchived.unshift(
          rootGetters.usersList.find((elem) => elem.id == userId)
        );
        rootGetters.usersList.splice(
          rootGetters.usersList.findIndex((elem) => elem.id == userId),
          1
        );
        commit(UserMutations.SET_USERS_LIST, rootGetters.usersList);
        commit(UserMutations.SET_REQUEST_STATE_USER, res);
        commit(UserMutations.SET_IS_ARCHIVING_USER, false);
      })
      .catch((err) => {
        console.error(err);
        commit(UserMutations.SET_REQUEST_STATE_USER, err);
        commit(UserMutations.SET_IS_ARCHIVING_USER, false);
      });
  },

  async restoreUser({ commit, rootGetters }, userId: string): Promise<void> {
    commit(UserMutations.SET_IS_UPDATING_USER, true);
    axios
      .put(process.env.VUE_APP_API_ORISIS + "Account/User/Restore", [userId], {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`,
        },
      })
      .then((res) => {
        rootGetters.usersList.unshift(
          rootGetters.usersListArchived.find((elem) => elem.id == userId)
        );
        rootGetters.usersListArchived.splice(
          rootGetters.usersListArchived.findIndex((elem) => elem.id == userId),
          1
        );
        commit(UserMutations.SET_REQUEST_STATE_USER, res);
        commit(UserMutations.SET_IS_UPDATING_USER, false);
      })
      .catch((err) => {
        console.error(err);
        commit(UserMutations.SET_REQUEST_STATE_USER, err);
        commit(UserMutations.SET_IS_UPDATING_USER, false);
      });
  },
};
