import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { DeviceTypeResponseDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../../RootState'
import { IDeviceTypeState } from './states'


export const getters: GetterTree<IDeviceTypeState, RootState> = {
  deviceTypesList: (state): DeviceTypeResponseDto[] => state.deviceTypesList,
  deviceTypesListArchived: (state): DeviceTypeResponseDto[] => state.deviceTypesListArchived,
  deviceType     : (state): DeviceTypeResponseDto => state.deviceType,

  // COMMON
  isLoadingDeviceTypesList   : (state): boolean => state.isLoadingDeviceTypesList,
  isLoadingDeviceTypesListArchived   : (state): boolean => state.isLoadingDeviceTypesListArchived,
  isLoadingDeviceType   : (state): boolean => state.isLoadingDeviceType,
  isCreatingDeviceType  : (state): boolean => state.isCreatingDeviceType,
  isUpdatingDeviceType  : (state): boolean => state.isUpdatingDeviceType,
  isDeletingDeviceType  : (state): boolean => state.isDeletingDeviceType,
  requestStateDeviceType: (state): IRequestState => state.requestStateDeviceType,
  isOpenSidebarDeviceType : (state): boolean => state.isOpenSidebarDeviceType,
  isArchivingDeviceType : (state): boolean => state.isArchivingDeviceType,
}