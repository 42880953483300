import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ProductResponseDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "../../rootState";
import { IProductState } from "./states";

export const getters: GetterTree<IProductState, RootState> = {
  productsList: (state): ProductResponseDto[] => state.productsList,
  productsListArchived: (state): ProductResponseDto[] =>
    state.productsListArchived,
  product: (state): ProductResponseDto => state.product,

  // COMMON
  isLoadingProductsList: (state): boolean => state.isLoadingProductsList,
  isLoadingProductsListArchived: (state): boolean =>
    state.isLoadingProductsListArchived,
  isLoadingProduct: (state): boolean => state.isLoadingProduct,
  isCreatingProduct: (state): boolean => state.isCreatingProduct,
  isUpdatingProduct: (state): boolean => state.isUpdatingProduct,
  isDeletingProduct: (state): boolean => state.isDeletingProduct,
  requestStateProduct: (state): IRequestState => state.requestStateProduct,
  isOpenSidebarProduct: (state): boolean => state.isOpenSidebarProduct,
  isArchivingProduct: (state): boolean => state.isArchivingProduct,
};
