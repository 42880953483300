import { IMissionState } from "./states"
import { Module } from "vuex"
import { MissionResponseDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IMissionState = {
    missionsList : Array<MissionResponseDto>(),
    commonMissionsList : Array<MissionResponseDto>(),
    missionsListArchived : Array<MissionResponseDto>(),
    commonMissionsListArchived : Array<MissionResponseDto>(),
    mission      : <MissionResponseDto>{},
    isLoadingMissionsList    : false,
    isLoadingCommonMissionsList    : false,
    isLoadingMissionsListArchived    : false,
    isLoadingCommonMissionsListArchived :false,
    isLoadingMission    : false,
    isCreatingMission   : false,
    isUpdatingMission   : false,
    isDeletingMission   : false,
    requestStateMission : <IRequestState>{},
    requestStateCommonMission : <IRequestState>{},
    isOpenSidebarMission : false,
    isArchivingMission : false,
}

export const MissionModule: Module<IMissionState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}