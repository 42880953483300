import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { UserRequestDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "../../RootState";
import { IUserState } from "./states";

export const getters: GetterTree<IUserState, RootState> = {
    usersList: (state): UserRequestDto[] => state.usersList,
    usersListArchived: (state): UserRequestDto[] => state.usersListArchived,
    user     : (state): UserRequestDto => state.user,
    
    // COMMON
    isLoadingUsersList   : (state): boolean => state.isLoadingUsersList,
    isLoadingUsersListArchived   : (state): boolean => state.isLoadingUsersListArchived,
    isLoadingUser   : (state): boolean => state.isLoadingUser,
    isCreatingUser  : (state): boolean => state.isCreatingUser,
    isUpdatingUser  : (state): boolean => state.isUpdatingUser,
    isDeletingUser  : (state): boolean => state.isDeletingUser,
    isArchivingUser : (state): boolean => state.isArchivingUser,
    requestStateUser: (state): IRequestState => state.requestStateUser,
    isOpenSidebarUser: (state): boolean => state.isOpenSidebarUser
}