import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
// import auth from "./modules/auth";
import createPersistedState from "vuex-persistedstate";
import register from "./modules/account/Register";
import login from "./modules/account/Login.js";

import { WorkspaceModule } from "./modules/account/workspace/index"; // fait mais il reste une fonction à placer au bon endroit
import { UserModule } from "./modules/directory/user/index";

import { CollaboratorModule } from "./modules/directory/collaborator/index";
import { CustomerModule } from "./modules/directory/customer/index";
import { PoolModule } from "./modules/directory/pool/index";
import { DeviceTypeModule } from "./modules/directory/types/deviceType";
import { ContractMaintenanceTypeModule } from "./modules/directory/types/contractMaintenanceType";
import { InterventionTypeModule } from "./modules/directory/types/interventionsTypes";
import { PoolTypeModule } from "./modules/directory/types/poolsTypes";
import { CoatingTypeModule } from "./modules/directory/types/coatingsTypes";
import { WaterTreatmentTypeModule } from "./modules/directory/types/waterTreatmentsTypes";
import { ProfessionalContractTypeModule } from "./modules/directory/types/professionalContractsTypes";
import { JobModule } from "./modules/parameters/job/index";
import { ProductUsedModule } from "./modules/directory/productUsed";
import { PoolAccessTypeModule } from "./modules/directory/types/poolAccessTypes";
import { AccessMethodTypeModule } from "./modules/directory/types/accessMethodTypes";
import { InterventionModule } from "./modules/directory/intervention";
import { InterventionStatisticsModule } from "./modules/directory/interventionStatistics";
import { DeviceModule } from "./modules/directory/device";
import { BrandModule } from "./modules/directory/brand";
import { StepModule } from "./modules/directory/step";
import { MissionModule } from "./modules/directory/mission";
import { MissionTypeModule } from "./modules/directory/types/tasksTypes";
import { ProductModule } from "./modules/directory/product";
import { FileModule } from "./modules/directory/file";
import { FilterModule } from "./modules/directory/filter";
import { AnalysisModule } from "./modules/directory/analysis";

Vue.use(Vuex);
const authState = createPersistedState({ paths: ["login"], key: "login" });
// const workspaceState = createPersistedState({ paths: ['workspaceSelected'], key: "workspaceSelected" });
const planningViewState = createPersistedState({
  paths: ["planningViewSelected"],
  key: "planningViewSelected",
});

const store: StoreOptions<{}> = {
  modules: {
    //app de base
    app,
    appConfig,
    verticalMenu,
    // auth,
    login,
    register,
    //-----------

    WorkspaceModule,
    UserModule,

    ProductModule,
    ProductUsedModule,
    CollaboratorModule,
    CustomerModule,
    PoolModule,
    DeviceTypeModule,
    ContractMaintenanceTypeModule,
    InterventionTypeModule,
    PoolTypeModule,
    CoatingTypeModule,
    WaterTreatmentTypeModule,
    ProfessionalContractTypeModule,
    JobModule,
    PoolAccessTypeModule,
    AccessMethodTypeModule,
    InterventionModule,
    DeviceModule,
    BrandModule,
    StepModule,
    MissionModule,
    MissionTypeModule,
    InterventionStatisticsModule,
    FileModule,
    FilterModule,
    AnalysisModule,
  },
  plugins: [authState, planningViewState],
};

export default new Vuex.Store<{}>(store);
