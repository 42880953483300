import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { InterventionResponseDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../RootState'
import { IInterventionState } from './states'


export const getters: GetterTree<IInterventionState, RootState> = {
  interventionsList: (state): InterventionResponseDto[] => state.interventionsList,
  interventionsListArchived: (state): InterventionResponseDto[] => state.interventionsListArchived,
  intervention     : (state): InterventionResponseDto => state.intervention,

  // COMMON
  isLoadingInterventionsList   : (state): boolean => state.isLoadingInterventionsList,
  isLoadingInterventionsListArchived   : (state): boolean => state.isLoadingInterventionsListArchived,
  isLoadingIntervention   : (state): boolean => state.isLoadingIntervention,
  isCreatingIntervention  : (state): boolean => state.isCreatingIntervention,
  isUpdatingIntervention  : (state): boolean => state.isUpdatingIntervention,
  isUpdatingStepIntervention  : (state): boolean => state.isUpdatingStepIntervention,
  isDeletingIntervention  : (state): boolean => state.isDeletingIntervention,
  requestStateIntervention: (state): IRequestState => state.requestStateIntervention,
  isOpenSidebarIntervention : (state): boolean => state.isOpenSidebarIntervention,
  isArchivingIntervention : (state): boolean => state.isArchivingIntervention,
}