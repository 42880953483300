import { IBrandState } from "./states"
import { Module } from "vuex"
import { BrandResponseDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IBrandState = {
    brandsList : Array<BrandResponseDto>(),
    brandsListArchived : Array<BrandResponseDto>(),
    brand      : <BrandResponseDto>{},
    isLoadingBrandsList    : false,
    isLoadingBrandsListArchived    : false,
    isLoadingBrand    : false,
    isCreatingBrand   : false,
    isUpdatingBrand   : false,
    isDeletingBrand   : false,
    requestStateBrand : <IRequestState>{},
    isOpenSidebarBrand : false,
    isArchivingBrand : false,
}

export const BrandModule: Module<IBrandState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}