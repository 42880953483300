import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { PoolTypeResponseDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IPoolTypeState } from "./states";

export enum PoolTypeMutations {
    SET_POOL_TYPES_LIST = "SET_POOL_TYPES_LIST",
    SET_POOL_TYPES_LIST_ARCHIVED = "SET_POOL_TYPES_LIST_ARCHIVED",
    SET_POOL_TYPE = "SET_POOL_TYPE",

    // COMMON
    SET_IS_LOADING_POOL_TYPES_LIST= "SET_IS_LOADING_POOL_TYPES_LIST",
    SET_IS_LOADING_POOL_TYPES_LIST_ARCHIVED= "SET_IS_LOADING_POOL_TYPES_LIST_ARCHIVED",
    
    SET_IS_LOADING_POOL_TYPE= "SET_IS_LOADING_POOL_TYPE",
    SET_IS_CREATING_POOL_TYPE = "SET_IS_CREATING_POOL_TYPE",
    SET_IS_UPDATING_POOL_TYPE= "SET_IS_UPDATING_POOL_TYPE",
    SET_IS_DELETING_POOL_TYPE = "SET_IS_DELETING_POOL_TYPE",
    SET_REQUEST_STATE_POOL_TYPE = "SET_REQUEST_STATE_POOL_TYPE",
    SET_IS_OPEN_SIDEBAR_POOL_TYPE = "SET_IS_OPEN_SIDEBAR_POOL_TYPE",
    SET_IS_ARCHIVING_POOL_TYPE = "SET_IS_ARCHIVING_POOL_TYPE"
}

export const mutations: MutationTree<IPoolTypeState> = {
    [PoolTypeMutations.SET_POOL_TYPES_LIST]: (state, payload: PoolTypeResponseDto[]) => {
        state.poolTypesList = payload;
    },
    [PoolTypeMutations.SET_POOL_TYPES_LIST_ARCHIVED]: (state, payload: PoolTypeResponseDto[]) => {
        state.poolTypesListArchived = payload;
    },
    [PoolTypeMutations.SET_POOL_TYPE]: (state, payload: PoolTypeResponseDto) => {
        state.poolType = payload;
    },


    //COMMON
    [PoolTypeMutations.SET_IS_LOADING_POOL_TYPES_LIST]: (state, payload: boolean) => {
        state.isLoadingPoolTypesList = payload;
    },
    [PoolTypeMutations.SET_IS_LOADING_POOL_TYPES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingPoolTypesListArchived = payload;
    },
    [PoolTypeMutations.SET_IS_LOADING_POOL_TYPE]: (state, payload: boolean) => {
        state.isLoadingPoolType = payload;
    },
    [PoolTypeMutations.SET_IS_CREATING_POOL_TYPE]: (state, payload: boolean) => {
        state.isCreatingPoolType = payload;
    },
    [PoolTypeMutations.SET_IS_UPDATING_POOL_TYPE]: (state, payload: boolean) => {
        state.isUpdatingPoolType = payload;
    },
    [PoolTypeMutations.SET_IS_DELETING_POOL_TYPE]: (state, payload: boolean) => {
        state.isDeletingPoolType = payload;
    },
    [PoolTypeMutations.SET_REQUEST_STATE_POOL_TYPE]: (state, payload: IRequestState) => {
        state.requestStatePoolType = payload;
    },
    [PoolTypeMutations.SET_IS_OPEN_SIDEBAR_POOL_TYPE]: (state, payload: boolean) => {
        state.isOpenSidebarPoolType = payload;
    },
    [PoolTypeMutations.SET_IS_ARCHIVING_POOL_TYPE]: (state, payload: boolean) => {
        state.isArchivingPoolType = payload;
    },
}