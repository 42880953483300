import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { FileResponseDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IFileState } from "./states";

export enum FileMutations {
    SET_FILES_LIST = "SET_FILES_LIST",
    SET_FILES_LIST_ARCHIVED = "SET_FILES_LIST_ARCHIVED",
    SET_FILE = "SET_FILE",

    // COMMON
    SET_IS_LOADING_FILES_LIST= "SET_IS_LOADING_FILES_LIST",
    SET_IS_LOADING_FILES_LIST_ARCHIVED= "SET_IS_LOADING_FILES_LIST_ARCHIVED",
    
    SET_IS_LOADING_FILE= "SET_IS_LOADING_FILE",
    SET_IS_CREATING_FILE = "SET_IS_CREATING_FILE",
    SET_IS_UPDATING_FILE= "SET_IS_UPDATING_FILE",
    SET_IS_UPDATING_STEP_FILE= "SET_IS_UPDATING_STEP_FILE",
    SET_IS_DELETING_FILE = "SET_IS_DELETING_FILE",
    SET_REQUEST_STATE_FILE = "SET_REQUEST_STATE_FILE",
    SET_IS_OPEN_SIDEBAR_FILE = "SET_IS_OPEN_SIDEBAR_FILE",
    SET_IS_ARCHIVING_FILE = "SET_IS_ARCHIVING_FILE"
}

export const mutations: MutationTree<IFileState> = {
    [FileMutations.SET_FILES_LIST]: (state, payload: FileResponseDto[]) => {
        state.filesList = payload;
    },
    [FileMutations.SET_FILES_LIST_ARCHIVED]: (state, payload: FileResponseDto[]) => {
        state.filesListArchived = payload;
    },
    [FileMutations.SET_FILE]: (state, payload: FileResponseDto) => {
        state.file = payload;
    },


    //COMMON
    [FileMutations.SET_IS_LOADING_FILES_LIST]: (state, payload: boolean) => {
        state.isLoadingFilesList = payload;
    },
    [FileMutations.SET_IS_LOADING_FILES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingFilesListArchived = payload;
    },
    [FileMutations.SET_IS_LOADING_FILE]: (state, payload: boolean) => {
        state.isLoadingFile = payload;
    },
    [FileMutations.SET_IS_CREATING_FILE]: (state, payload: boolean) => {
        state.isCreatingFile = payload;
    },
    [FileMutations.SET_IS_UPDATING_FILE]: (state, payload: boolean) => {
        state.isUpdatingFile = payload;
    },
    [FileMutations.SET_IS_UPDATING_STEP_FILE]: (state, payload: boolean) => {
        state.isUpdatingStepFile = payload;
    },
    [FileMutations.SET_IS_DELETING_FILE]: (state, payload: boolean) => {
        state.isDeletingFile = payload;
    },
    [FileMutations.SET_REQUEST_STATE_FILE]: (state, payload: IRequestState) => {
        state.requestStateFile = payload;
    },
    [FileMutations.SET_IS_OPEN_SIDEBAR_FILE]: (state, payload: boolean) => {
        state.isOpenSidebarFile = payload;
    },
    [FileMutations.SET_IS_ARCHIVING_FILE]: (state, payload: boolean) => {
        state.isArchivingFile = payload;
    },
}