import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { BrandResponseDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IBrandState } from "./states";

export enum BrandMutations {
    SET_BRANDS_LIST = "SET_BRANDS_LIST",
    SET_BRANDS_LIST_ARCHIVED = "SET_BRANDS_LIST_ARCHIVED",
    SET_BRAND = "SET_BRAND",

    // COMMON
    SET_IS_LOADING_BRANDS_LIST= "SET_IS_LOADING_BRANDS_LIST",
    SET_IS_LOADING_BRANDS_LIST_ARCHIVED= "SET_IS_LOADING_BRANDS_LIST_ARCHIVED",
    
    SET_IS_LOADING_BRAND= "SET_IS_LOADING_BRAND",
    SET_IS_CREATING_BRAND = "SET_IS_CREATING_BRAND",
    SET_IS_UPDATING_BRAND= "SET_IS_UPDATING_BRAND",
    SET_IS_DELETING_BRAND = "SET_IS_DELETING_BRAND",
    SET_REQUEST_STATE_BRAND = "SET_REQUEST_STATE_BRAND",
    SET_IS_OPEN_SIDEBAR_BRAND = "SET_IS_OPEN_SIDEBAR_BRAND",
    SET_IS_ARCHIVING_BRAND = "SET_IS_ARCHIVING_BRAND"
}

export const mutations: MutationTree<IBrandState> = {
    [BrandMutations.SET_BRANDS_LIST]: (state, payload: BrandResponseDto[]) => {
        state.brandsList = payload;
    },
    [BrandMutations.SET_BRANDS_LIST_ARCHIVED]: (state, payload: BrandResponseDto[]) => {
        state.brandsListArchived = payload;
    },
    [BrandMutations.SET_BRAND]: (state, payload: BrandResponseDto) => {
        state.brand = payload;
    },


    //COMMON
    [BrandMutations.SET_IS_LOADING_BRANDS_LIST]: (state, payload: boolean) => {
        state.isLoadingBrandsList = payload;
    },
    [BrandMutations.SET_IS_LOADING_BRANDS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingBrandsListArchived = payload;
    },
    [BrandMutations.SET_IS_LOADING_BRAND]: (state, payload: boolean) => {
        state.isLoadingBrand = payload;
    },
    [BrandMutations.SET_IS_CREATING_BRAND]: (state, payload: boolean) => {
        state.isCreatingBrand = payload;
    },
    [BrandMutations.SET_IS_UPDATING_BRAND]: (state, payload: boolean) => {
        state.isUpdatingBrand = payload;
    },
    [BrandMutations.SET_IS_DELETING_BRAND]: (state, payload: boolean) => {
        state.isDeletingBrand = payload;
    },
    [BrandMutations.SET_REQUEST_STATE_BRAND]: (state, payload: IRequestState) => {
        state.requestStateBrand = payload;
    },
    [BrandMutations.SET_IS_OPEN_SIDEBAR_BRAND]: (state, payload: boolean) => {
        state.isOpenSidebarBrand = payload;
    },
    [BrandMutations.SET_IS_ARCHIVING_BRAND]: (state, payload: boolean) => {
        state.isArchivingBrand = payload;
    },
}