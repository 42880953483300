import { IDeviceTypeState } from "./states"
import { Module } from "vuex"
import { DeviceTypeResponseDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IDeviceTypeState = {
    deviceTypesList : Array<DeviceTypeResponseDto>(),
    deviceTypesListArchived : Array<DeviceTypeResponseDto>(),
    deviceType      : <DeviceTypeResponseDto>{},
    isLoadingDeviceTypesList    : false,
    isLoadingDeviceTypesListArchived    : false,
    isLoadingDeviceType    : false,
    isCreatingDeviceType   : false,
    isUpdatingDeviceType   : false,
    isDeletingDeviceType   : false,
    requestStateDeviceType : <IRequestState>{},
    isOpenSidebarDeviceType : false,
    isArchivingDeviceType : false,
}

export const DeviceTypeModule: Module<IDeviceTypeState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}