import { IPoolAccessTypeState } from "./states"
import { Module } from "vuex"
import { PoolAccessTypeResponseDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IPoolAccessTypeState = {
    poolAccessTypesList : Array<PoolAccessTypeResponseDto>(),
    poolAccessTypesListArchived : Array<PoolAccessTypeResponseDto>(),
    poolAccessType      : <PoolAccessTypeResponseDto>{},
    isLoadingPoolAccessTypesList    : false,
    isLoadingPoolAccessTypesListArchived    : false,
    isLoadingPoolAccessType    : false,
    isCreatingPoolAccessType   : false,
    isUpdatingPoolAccessType   : false,
    isDeletingPoolAccessType   : false,
    requestStatePoolAccessType : <IRequestState>{},
    isOpenSidebarPoolAccessType : false,
    isArchivingPoolAccessType : false,
}

export const PoolAccessTypeModule: Module<IPoolAccessTypeState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}