import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { PoolResponseDto } from "@/../Api";
import { MutationTree } from "vuex";
import { IPoolState } from "./states";

export enum PoolMutations {
  SET_POOLS_LIST = "SET_POOLS_LIST",
  SET_POOLS_LIST_ARCHIVED = "SET_POOLS_LIST_ARCHIVED",
  SET_POOL = "SET_POOL",

  // COMMON
  SET_IS_LOADING_POOLS_LIST = "SET_IS_LOADING_POOLS_LIST",
  SET_IS_LOADING_POOLS_LIST_ARCHIVED = "SET_IS_LOADING_POOLS_LIST_ARCHIVED",

  SET_IS_LOADING_POOL = "SET_IS_LOADING_POOL",
  SET_IS_CREATING_POOL = "SET_IS_CREATING_POOL",
  SET_IS_UPDATING_POOL = "SET_IS_UPDATING_POOL",
  SET_IS_DELETING_POOL = "SET_IS_DELETING_POOL",
  SET_REQUEST_STATE_POOL = "SET_REQUEST_STATE_POOL",
  SET_IS_OPEN_SIDEBAR_POOL = "SET_IS_OPEN_SIDEBAR_POOL",
  SET_IS_ARCHIVING_POOL = "SET_IS_ARCHIVING_POOL",
}

export const mutations: MutationTree<IPoolState> = {
  [PoolMutations.SET_POOLS_LIST]: (state, payload: PoolResponseDto[]) => {
    state.poolsList = payload;
  },
  [PoolMutations.SET_POOLS_LIST_ARCHIVED]: (
    state,
    payload: PoolResponseDto[]
  ) => {
    state.poolsListArchived = payload;
  },
  [PoolMutations.SET_POOL]: (state, payload: PoolResponseDto) => {
    state.pool = payload;
  },

  //COMMON
  [PoolMutations.SET_IS_LOADING_POOLS_LIST]: (state, payload: boolean) => {
    state.isLoadingPoolsList = payload;
  },
  [PoolMutations.SET_IS_LOADING_POOLS_LIST_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingPoolsListArchived = payload;
  },
  [PoolMutations.SET_IS_LOADING_POOL]: (state, payload: boolean) => {
    state.isLoadingPool = payload;
  },
  [PoolMutations.SET_IS_CREATING_POOL]: (state, payload: boolean) => {
    state.isCreatingPool = payload;
  },
  [PoolMutations.SET_IS_UPDATING_POOL]: (state, payload: boolean) => {
    state.isUpdatingPool = payload;
  },
  [PoolMutations.SET_IS_DELETING_POOL]: (state, payload: boolean) => {
    state.isDeletingPool = payload;
  },
  [PoolMutations.SET_REQUEST_STATE_POOL]: (state, payload: IRequestState) => {
    state.requestStatePool = payload;
  },
  [PoolMutations.SET_IS_OPEN_SIDEBAR_POOL]: (state, payload: boolean) => {
    state.isOpenSidebarPool = payload;
  },
  [PoolMutations.SET_IS_ARCHIVING_POOL]: (state, payload: boolean) => {
    state.isArchivingPool = payload;
  },
};
