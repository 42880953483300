import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { DeviceTypeResponseDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IDeviceTypeState } from "./states";

export enum DeviceTypeMutations {
    SET_DEVICE_TYPES_LIST = "SET_DEVICE_TYPES_LIST",
    SET_DEVICE_TYPES_LIST_ARCHIVED = "SET_DEVICE_TYPES_LIST_ARCHIVED",
    SET_DEVICE_TYPE = "SET_DEVICE_TYPE",

    // COMMON
    SET_IS_LOADING_DEVICE_TYPES_LIST= "SET_IS_LOADING_DEVICE_TYPES_LIST",
    SET_IS_LOADING_DEVICE_TYPES_LIST_ARCHIVED= "SET_IS_LOADING_DEVICE_TYPES_LIST_ARCHIVED",
    
    SET_IS_LOADING_DEVICE_TYPE= "SET_IS_LOADING_DEVICE_TYPE",
    SET_IS_CREATING_DEVICE_TYPE = "SET_IS_CREATING_DEVICE_TYPE",
    SET_IS_UPDATING_DEVICE_TYPE= "SET_IS_UPDATING_DEVICE_TYPE",
    SET_IS_DELETING_DEVICE_TYPE = "SET_IS_DELETING_DEVICE_TYPE",
    SET_REQUEST_STATE_DEVICE_TYPE = "SET_REQUEST_STATE_DEVICE_TYPE",
    SET_IS_OPEN_SIDEBAR_DEVICE_TYPE = "SET_IS_OPEN_SIDEBAR_DEVICE_TYPE",
    SET_IS_ARCHIVING_DEVICE_TYPE = "SET_IS_ARCHIVING_DEVICE_TYPE"
}

export const mutations: MutationTree<IDeviceTypeState> = {
    [DeviceTypeMutations.SET_DEVICE_TYPES_LIST]: (state, payload: DeviceTypeResponseDto[]) => {
        state.deviceTypesList = payload;
    },
    [DeviceTypeMutations.SET_DEVICE_TYPES_LIST_ARCHIVED]: (state, payload: DeviceTypeResponseDto[]) => {
        state.deviceTypesListArchived = payload;
    },
    [DeviceTypeMutations.SET_DEVICE_TYPE]: (state, payload: DeviceTypeResponseDto) => {
        state.deviceType = payload;
    },


    //COMMON
    [DeviceTypeMutations.SET_IS_LOADING_DEVICE_TYPES_LIST]: (state, payload: boolean) => {
        state.isLoadingDeviceTypesList = payload;
    },
    [DeviceTypeMutations.SET_IS_LOADING_DEVICE_TYPES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingDeviceTypesListArchived = payload;
    },
    [DeviceTypeMutations.SET_IS_LOADING_DEVICE_TYPE]: (state, payload: boolean) => {
        state.isLoadingDeviceType = payload;
    },
    [DeviceTypeMutations.SET_IS_CREATING_DEVICE_TYPE]: (state, payload: boolean) => {
        state.isCreatingDeviceType = payload;
    },
    [DeviceTypeMutations.SET_IS_UPDATING_DEVICE_TYPE]: (state, payload: boolean) => {
        state.isUpdatingDeviceType = payload;
    },
    [DeviceTypeMutations.SET_IS_DELETING_DEVICE_TYPE]: (state, payload: boolean) => {
        state.isDeletingDeviceType = payload;
    },
    [DeviceTypeMutations.SET_REQUEST_STATE_DEVICE_TYPE]: (state, payload: IRequestState) => {
        state.requestStateDeviceType = payload;
    },
    [DeviceTypeMutations.SET_IS_OPEN_SIDEBAR_DEVICE_TYPE]: (state, payload: boolean) => {
        state.isOpenSidebarDeviceType = payload;
    },
    [DeviceTypeMutations.SET_IS_ARCHIVING_DEVICE_TYPE]: (state, payload: boolean) => {
        state.isArchivingDeviceType = payload;
    },
}