import { ICustomerState } from "./states"
import { Module } from "vuex"
import { CustomerResponseDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:ICustomerState = {
    customersList : Array<CustomerResponseDto>(),
    customersListArchived : Array<CustomerResponseDto>(),
    customer      : <CustomerResponseDto>{},
    isLoadingCustomersList    : false,
    isLoadingCustomersListArchived    : false,
    isLoadingCustomer    : false,
    isCreatingCustomer   : false,
    isUpdatingCustomer   : false,
    isDeletingCustomer   : false,
    requestStateCustomer : <IRequestState>{},
    isOpenSidebarCustomer : false,
    isArchivingCustomer : false,
}

export const CustomerModule: Module<ICustomerState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}