import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { CoatingTypeResponseDto } from '@/../Api'
import { MutationTree } from "vuex";
import { ICoatingTypeState } from "./states";

export enum CoatingTypeMutations {
    SET_COATING_TYPES_LIST = "SET_COATING_TYPES_LIST",
    SET_COATING_TYPES_LIST_ARCHIVED = "SET_COATING_TYPES_LIST_ARCHIVED",
    SET_COATING_TYPE = "SET_COATING_TYPE",

    // COMMON
    SET_IS_LOADING_COATING_TYPES_LIST= "SET_IS_LOADING_COATING_TYPES_LIST",
    SET_IS_LOADING_COATING_TYPES_LIST_ARCHIVED= "SET_IS_LOADING_COATING_TYPES_LIST_ARCHIVED",
    
    SET_IS_LOADING_COATING_TYPE= "SET_IS_LOADING_COATING_TYPE",
    SET_IS_CREATING_COATING_TYPE = "SET_IS_CREATING_COATING_TYPE",
    SET_IS_UPDATING_COATING_TYPE= "SET_IS_UPDATING_COATING_TYPE",
    SET_IS_DELETING_COATING_TYPE = "SET_IS_DELETING_COATING_TYPE",
    SET_REQUEST_STATE_COATING_TYPE = "SET_REQUEST_STATE_COATING_TYPE",
    SET_IS_OPEN_SIDEBAR_COATING_TYPE = "SET_IS_OPEN_SIDEBAR_COATING_TYPE",
    SET_IS_ARCHIVING_COATING_TYPE = "SET_IS_ARCHIVING_COATING_TYPE"
}

export const mutations: MutationTree<ICoatingTypeState> = {
    [CoatingTypeMutations.SET_COATING_TYPES_LIST]: (state, payload: CoatingTypeResponseDto[]) => {
        state.coatingTypesList = payload;
    },
    [CoatingTypeMutations.SET_COATING_TYPES_LIST_ARCHIVED]: (state, payload: CoatingTypeResponseDto[]) => {
        state.coatingTypesListArchived = payload;
    },
    [CoatingTypeMutations.SET_COATING_TYPE]: (state, payload: CoatingTypeResponseDto) => {
        state.coatingType = payload;
    },


    //COMMON
    [CoatingTypeMutations.SET_IS_LOADING_COATING_TYPES_LIST]: (state, payload: boolean) => {
        state.isLoadingCoatingTypesList = payload;
    },
    [CoatingTypeMutations.SET_IS_LOADING_COATING_TYPES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingCoatingTypesListArchived = payload;
    },
    [CoatingTypeMutations.SET_IS_LOADING_COATING_TYPE]: (state, payload: boolean) => {
        state.isLoadingCoatingType = payload;
    },
    [CoatingTypeMutations.SET_IS_CREATING_COATING_TYPE]: (state, payload: boolean) => {
        state.isCreatingCoatingType = payload;
    },
    [CoatingTypeMutations.SET_IS_UPDATING_COATING_TYPE]: (state, payload: boolean) => {
        state.isUpdatingCoatingType = payload;
    },
    [CoatingTypeMutations.SET_IS_DELETING_COATING_TYPE]: (state, payload: boolean) => {
        state.isDeletingCoatingType = payload;
    },
    [CoatingTypeMutations.SET_REQUEST_STATE_COATING_TYPE]: (state, payload: IRequestState) => {
        state.requestStateCoatingType = payload;
    },
    [CoatingTypeMutations.SET_IS_OPEN_SIDEBAR_COATING_TYPE]: (state, payload: boolean) => {
        state.isOpenSidebarCoatingType = payload;
    },
    [CoatingTypeMutations.SET_IS_ARCHIVING_COATING_TYPE]: (state, payload: boolean) => {
        state.isArchivingCoatingType = payload;
    },
}