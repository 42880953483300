import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { WaterTreatmentTypeResponseDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../../RootState'
import { IWaterTreatmentTypeState } from './states'


export const getters: GetterTree<IWaterTreatmentTypeState, RootState> = {
  waterTreatmentTypesList: (state): WaterTreatmentTypeResponseDto[] => state.waterTreatmentTypesList,
  waterTreatmentTypesListArchived: (state): WaterTreatmentTypeResponseDto[] => state.waterTreatmentTypesListArchived,
  waterTreatmentType     : (state): WaterTreatmentTypeResponseDto => state.waterTreatmentType,

  // COMMON
  isLoadingWaterTreatmentTypesList   : (state): boolean => state.isLoadingWaterTreatmentTypesList,
  isLoadingWaterTreatmentTypesListArchived   : (state): boolean => state.isLoadingWaterTreatmentTypesListArchived,
  isLoadingWaterTreatmentType   : (state): boolean => state.isLoadingWaterTreatmentType,
  isCreatingWaterTreatmentType  : (state): boolean => state.isCreatingWaterTreatmentType,
  isUpdatingWaterTreatmentType  : (state): boolean => state.isUpdatingWaterTreatmentType,
  isDeletingWaterTreatmentType  : (state): boolean => state.isDeletingWaterTreatmentType,
  requestStateWaterTreatmentType: (state): IRequestState => state.requestStateWaterTreatmentType,
  isOpenSidebarWaterTreatmentType : (state): boolean => state.isOpenSidebarWaterTreatmentType,
  isArchivingWaterTreatmentType : (state): boolean => state.isArchivingWaterTreatmentType,
}