import { IAnalysisState } from "./states";
import { Module } from "vuex";
import { AnalysisResponseDto } from "@/../Api";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/RootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const state: IAnalysisState = {
  analysisList: Array<AnalysisResponseDto>(),
  analysisListArchived: Array<AnalysisResponseDto>(),
  analysis: <AnalysisResponseDto>{},
  isLoadingAnalysisList: false,
  isLoadingAnalysisListArchived: false,
  isLoadingAnalysis: false,
  isCreatingAnalysis: false,
  isUpdatingAnalysis: false,
  isDeletingAnalysis: false,
  requestStateAnalysis: <IRequestState>{},
  isOpenSidebarAnalysis: false,
  isArchivingAnalysis: false,
};

export const AnalysisModule: Module<IAnalysisState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
