import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ProductResponseDto } from "@/../Api";
import { MutationTree } from "vuex";
import { IProductState } from "./states";

export enum ProductMutations {
  SET_PRODUCTS_LIST = "SET_PRODUCTS_LIST",
  SET_PRODUCTS_LIST_ARCHIVED = "SET_PRODUCTS_LIST_ARCHIVED",
  SET_PRODUCT = "SET_PRODUCT",

  // COMMON
  SET_IS_LOADING_PRODUCTS_LIST = "SET_IS_LOADING_PRODUCTS_LIST",
  SET_IS_LOADING_PRODUCTS_LIST_ARCHIVED = "SET_IS_LOADING_PRODUCTS_LIST_ARCHIVED",

  SET_IS_LOADING_PRODUCT = "SET_IS_LOADING_PRODUCT",
  SET_IS_CREATING_PRODUCT = "SET_IS_CREATING_PRODUCT",
  SET_IS_UPDATING_PRODUCT = "SET_IS_UPDATING_PRODUCT",
  SET_IS_DELETING_PRODUCT = "SET_IS_DELETING_PRODUCT",
  SET_REQUEST_STATE_PRODUCT = "SET_REQUEST_STATE_PRODUCT",
  SET_IS_OPEN_SIDEBAR_PRODUCT = "SET_IS_OPEN_SIDEBAR_PRODUCT",
  SET_IS_ARCHIVING_PRODUCT = "SET_IS_ARCHIVING_PRODUCT",
}

export const mutations: MutationTree<IProductState> = {
  [ProductMutations.SET_PRODUCTS_LIST]: (
    state,
    payload: ProductResponseDto[]
  ) => {
    state.productsList = payload;
  },
  [ProductMutations.SET_PRODUCTS_LIST_ARCHIVED]: (
    state,
    payload: ProductResponseDto[]
  ) => {
    state.productsListArchived = payload;
  },
  [ProductMutations.SET_PRODUCT]: (state, payload: ProductResponseDto) => {
    state.product = payload;
  },

  //COMMON
  [ProductMutations.SET_IS_LOADING_PRODUCTS_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingProductsList = payload;
  },
  [ProductMutations.SET_IS_LOADING_PRODUCTS_LIST_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingProductsListArchived = payload;
  },
  [ProductMutations.SET_IS_LOADING_PRODUCT]: (state, payload: boolean) => {
    state.isLoadingProduct = payload;
  },
  [ProductMutations.SET_IS_CREATING_PRODUCT]: (state, payload: boolean) => {
    state.isCreatingProduct = payload;
  },
  [ProductMutations.SET_IS_UPDATING_PRODUCT]: (state, payload: boolean) => {
    state.isUpdatingProduct = payload;
  },
  [ProductMutations.SET_IS_DELETING_PRODUCT]: (state, payload: boolean) => {
    state.isDeletingProduct = payload;
  },
  [ProductMutations.SET_REQUEST_STATE_PRODUCT]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateProduct = payload;
  },
  [ProductMutations.SET_IS_OPEN_SIDEBAR_PRODUCT]: (state, payload: boolean) => {
    state.isOpenSidebarProduct = payload;
  },
  [ProductMutations.SET_IS_ARCHIVING_PRODUCT]: (state, payload: boolean) => {
    state.isArchivingProduct = payload;
  },
};
