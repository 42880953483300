import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ContractResponseDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IProfessionalContractTypeState } from "./states";

export enum ProfessionalContractTypeMutations {
    SET_PROFESSIONAL_CONTRACT_TYPES_LIST = "SET_PROFESSIONAL_CONTRACT_TYPES_LIST",
    SET_PROFESSIONAL_CONTRACT_TYPES_LIST_ARCHIVED = "SET_PROFESSIONAL_CONTRACT_TYPES_LIST_ARCHIVED",
    SET_PROFESSIONAL_CONTRACT_TYPE = "SET_PROFESSIONAL_CONTRACT_TYPE",

    // COMMON
    SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPES_LIST= "SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPES_LIST",
    SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPES_LIST_ARCHIVED= "SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPES_LIST_ARCHIVED",
    
    SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPE= "SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPE",
    SET_IS_CREATING_PROFESSIONAL_CONTRACT_TYPE = "SET_IS_CREATING_PROFESSIONAL_CONTRACT_TYPE",
    SET_IS_UPDATING_PROFESSIONAL_CONTRACT_TYPE= "SET_IS_UPDATING_PROFESSIONAL_CONTRACT_TYPE",
    SET_IS_DELETING_PROFESSIONAL_CONTRACT_TYPE = "SET_IS_DELETING_PROFESSIONAL_CONTRACT_TYPE",
    SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE = "SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE",
    SET_IS_OPEN_SIDEBAR_PROFESSIONAL_CONTRACT_TYPE = "SET_IS_OPEN_SIDEBAR_PROFESSIONAL_CONTRACT_TYPE",
    SET_IS_ARCHIVING_PROFESSIONAL_CONTRACT_TYPE = "SET_IS_ARCHIVING_PROFESSIONAL_CONTRACT_TYPE"
}

export const mutations: MutationTree<IProfessionalContractTypeState> = {
    [ProfessionalContractTypeMutations.SET_PROFESSIONAL_CONTRACT_TYPES_LIST]: (state, payload: ContractResponseDto[]) => {
        state.professionalContractTypesList = payload;
    },
    [ProfessionalContractTypeMutations.SET_PROFESSIONAL_CONTRACT_TYPES_LIST_ARCHIVED]: (state, payload: ContractResponseDto[]) => {
        state.professionalContractTypesListArchived = payload;
    },
    [ProfessionalContractTypeMutations.SET_PROFESSIONAL_CONTRACT_TYPE]: (state, payload: ContractResponseDto) => {
        state.professionalContractType = payload;
    },


    //COMMON
    [ProfessionalContractTypeMutations.SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPES_LIST]: (state, payload: boolean) => {
        state.isLoadingProfessionalContractTypesList = payload;
    },
    [ProfessionalContractTypeMutations.SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingProfessionalContractTypesListArchived = payload;
    },
    [ProfessionalContractTypeMutations.SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPE]: (state, payload: boolean) => {
        state.isLoadingProfessionalContractType = payload;
    },
    [ProfessionalContractTypeMutations.SET_IS_CREATING_PROFESSIONAL_CONTRACT_TYPE]: (state, payload: boolean) => {
        state.isCreatingProfessionalContractType = payload;
    },
    [ProfessionalContractTypeMutations.SET_IS_UPDATING_PROFESSIONAL_CONTRACT_TYPE]: (state, payload: boolean) => {
        state.isUpdatingProfessionalContractType = payload;
    },
    [ProfessionalContractTypeMutations.SET_IS_DELETING_PROFESSIONAL_CONTRACT_TYPE]: (state, payload: boolean) => {
        state.isDeletingProfessionalContractType = payload;
    },
    [ProfessionalContractTypeMutations.SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE]: (state, payload: IRequestState) => {
        state.requestStateProfessionalContractType = payload;
    },
    [ProfessionalContractTypeMutations.SET_IS_OPEN_SIDEBAR_PROFESSIONAL_CONTRACT_TYPE]: (state, payload: boolean) => {
        state.isOpenSidebarProfessionalContractType = payload;
    },
    [ProfessionalContractTypeMutations.SET_IS_ARCHIVING_PROFESSIONAL_CONTRACT_TYPE]: (state, payload: boolean) => {
        state.isArchivingProfessionalContractType = payload;
    },
}