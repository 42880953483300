import { AnalysisResponseDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "../../RootState";
import { AnalysisMutations } from "./mutations";
import { IAnalysisState } from "./states";

export const actions: ActionTree<IAnalysisState, RootState> = {
  /**
   * It fetches the list of analysis from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */

  async fetchLastAnalysisByPoolId({ commit, rootGetters }, poolId: string): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(AnalysisMutations.SET_IS_LOADING_ANALYSIS_LIST, true);
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Management/Analysis/GetLastAnalysisByPoolId", {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          params: {
            poolId: poolId,
          },
        })
        .then((res) => {
          commit(AnalysisMutations.SET_ANALYSIS_LIST, res.data);
          commit(AnalysisMutations.SET_REQUEST_STATE_ANALYSE, res);
          commit(AnalysisMutations.SET_IS_LOADING_ANALYSIS_LIST, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(AnalysisMutations.SET_REQUEST_STATE_ANALYSE, err);
          commit(AnalysisMutations.SET_IS_LOADING_ANALYSIS_LIST, false);
          reject(err);
        });
    });
  },
};
