// import { GET_ALL_PRODUCTS } from "./Types";
import axios from 'axios';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// initial state
const state = () => ({


})

// getters
const getters = {

};

// actions
const actions = {


  async register({
    commit,
    rootState
  }, {
    payload,
    that
  }) {
    var config = {
      method: "post",
      url: process.env.VUE_APP_API_ORISIS + "Account/Register",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    axios(config)
      .then(function () {
        that.$router.push("/login");
        vm.$bvToast.toast("Vous êtes correctement inscrit !", {
          title: `Félicitation ${payload.firstName}`,
          variant: "success",
          solid: true,
        })
      })


  },
}

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  
}
