import { WorkspaceRequestDto } from '@/../Api'

import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { WorkspaceMutations } from './mutations'
import { IWorkspaceState } from './states'
import {mutations} from '../Login'

export const actions: ActionTree<IWorkspaceState, RootState> = {
  /**
   * It fetches the list of workspaces from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchWorkspacesList (  { commit, rootGetters } ): Promise<void> {
    commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACES_LIST, true)
    await axios
      .get(
        `${process.env.VUE_APP_API_ORISIS}Account/Workspace/GetWorkspaces`,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        commit(WorkspaceMutations.SET_WORKSPACES_LIST, res.data)
        commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res)
        commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACES_LIST, false)
      })
      .catch(err => {
        commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err)
        commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACES_LIST, false)
      })
  },

  /* Fetching the workspace data from the API. */ 
  async fetchWorkspace (
    { commit, rootGetters },
    workspaceId: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) =>{
      commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Account/Workspace/GetWorkspaceById',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          params: {
            id: workspaceId
          }
        }
      ) 
      .then(res => {
        let workspace = {...res.data, modules: res.data.modules.map((el) => el.id )}
        commit(WorkspaceMutations.SET_WORKSPACE, workspace)
        commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res)
        commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, false)
        resolve(workspace)
      })
      .catch(err => {
        console.error(err)
        commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err)
        commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, false)
      })
    })
  },

  /**
   * It creates a new workspace.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {WorkspaceRequestDto} workspace - WorkspaceRequestDto
   */
  async createWorkspace (
    { commit, rootGetters },
    workspace: WorkspaceRequestDto
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(WorkspaceMutations.SET_IS_CREATING_WORKSPACE, true)
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Account/Workspace/CreateWorkspace`,
          workspace,
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            }
          }
        )
        .then(res => {
          rootGetters.workspacesList.unshift(res.data.data)
          commit(
            WorkspaceMutations.SET_WORKSPACES_LIST,
            rootGetters.workspacesList
          )
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res)
          commit(WorkspaceMutations.SET_IS_CREATING_WORKSPACE, false)
          resolve(res.data.data)
        })
        .catch(err => {
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err)
          commit(WorkspaceMutations.SET_IS_CREATING_WORKSPACE, false)
          reject(err)
        })
    })
  },

  /**
   * Update an workspace
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {WorkspaceRequestDto} workspace - WorkspaceRequestDto
   */ 
  updateWorkspace ({ commit, rootGetters }, workspace: WorkspaceRequestDto) {
    return new Promise(async (resolve, reject) => {
      commit(WorkspaceMutations.SET_IS_UPDATING_WORKSPACE, true)
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Account/Workspace/UpdateWorkspace`,
          workspace,
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            }
          }
        )
        .then(res => { 
          rootGetters.workspacesList.splice(
            rootGetters.workspacesList.findIndex(elem => elem.id == workspace.id),
            1,
            workspace
          )
          commit(
            WorkspaceMutations.SET_WORKSPACES_LIST,
            rootGetters.workspacesList
          )
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res)
          commit(WorkspaceMutations.SET_IS_UPDATING_WORKSPACE, false)
          resolve(res.data.data)
        })
        .catch(err => {
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err)
          commit(WorkspaceMutations.SET_IS_UPDATING_WORKSPACE, false)
          reject()
        })
    })
  },

  updateWorkspaceModule ({ commit, rootGetters }, { modules, workspaceId }) {
    return new Promise(async (resolve, reject) => {
      commit(WorkspaceMutations.SET_IS_UPDATING_WORKSPACE, true)
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Account/Workspace/UpdateWorkspaceModule`,
          modules,
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              workspaceId: workspaceId //rootGetters.workspaceSelected.id
            }
          }
        )
        .then(res => { 
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res)
          commit(WorkspaceMutations.SET_IS_UPDATING_WORKSPACE, false)
          resolve(res)
        })
        .catch(err => {
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err)
          commit(WorkspaceMutations.SET_IS_UPDATING_WORKSPACE, false)
          reject()
        })
    })
  },
 
  setWorkspaceSelected({commit, dispatch, rootGetters}, {workspaceIdSelected}) {
    return new Promise((resolve, reject) => {
      commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, true)
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            `Account/ChooseWorkspace?workspaceIdSelected=${workspaceIdSelected}`,null, 
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            }
          }
        ) 
        .then((res) => {
          localStorage.setItem('userToken', res.data.token)
          commit(WorkspaceMutations.SET_WORKSPACE_SELECTED, res.data.workspaces.find(elem => elem.id === workspaceIdSelected))
          commit("SET_USER_TOKEN", res.data.token)
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res)
          commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, false)          
          // that.$tabs.close();
          // that.$tabs.refreshAll();
          resolve(res.data)
          
        })
        .catch(err => {
          console.error(err)
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err)
          commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, false)
          reject(err)
        })
      })
  }
  // async setWorkspaceSelected({commit, dispatch, rootGetters}, {workspaceSelectedId}): Promise<void> {
  //   return new Promise(async (resolve, reject) => {
  //     commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, true)
  //     await axios
  //       .get(
  //         process.env.VUE_APP_API_ORISIS +
  //           'Account/Workspace/GetWorkspaceById',
  //         {
  //           headers: {
  //             Authorization: `Bearer ${rootGetters.userToken}`
  //           },
  //           params: {
  //             id: workspaceSelectedId
  //           }
  //         }
  //       ) 
  //       .then(async (res) => {
  //         commit(WorkspaceMutations.SET_WORKSPACE_SELECTED, res.data)
  //         commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res.data)
  //         commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, false)
  //         await dispatch('initializeStore')
  //         resolve()
  //       })
  //       .catch(err => {
  //         console.error(err)
  //         commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err)
  //         commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, false)
  //         reject()
  //       })
  //     })
  // }
}
