import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { UserRequestDto } from "@/../Api";
import RootState from "@/store/modules/RootState"
import { IUserState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IUserState = {
    usersList       : Array<UserRequestDto>(),
    usersListArchived : Array<UserRequestDto>(),
    user            : <UserRequestDto>{},
    isLoadingUsersList   : false,
    isLoadingUsersListArchived   : false,
    isLoadingUser   : false,
    isCreatingUser  : false,
    isUpdatingUser  : false,
    isDeletingUser  : false,
    isArchivingUser : false,
    requestStateUser: <IRequestState>{},
    isOpenSidebarUser : false,
}

export const UserModule: Module<IUserState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}