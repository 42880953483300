import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { FileResponseDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../RootState'
import { IFileState } from './states'


export const getters: GetterTree<IFileState, RootState> = {
  filesList: (state): FileResponseDto[] => state.filesList,
  filesListArchived: (state): FileResponseDto[] => state.filesListArchived,
  file     : (state): FileResponseDto => state.file,

  // COMMON
  isLoadingFilesList   : (state): boolean => state.isLoadingFilesList,
  isLoadingFilesListArchived   : (state): boolean => state.isLoadingFilesListArchived,
  isLoadingFile   : (state): boolean => state.isLoadingFile,
  isCreatingFile  : (state): boolean => state.isCreatingFile,
  isUpdatingFile  : (state): boolean => state.isUpdatingFile,
  isUpdatingStepFile  : (state): boolean => state.isUpdatingStepFile,
  isDeletingFile  : (state): boolean => state.isDeletingFile,
  requestStateFile: (state): IRequestState => state.requestStateFile,
  isOpenSidebarFile : (state): boolean => state.isOpenSidebarFile,
  isArchivingFile : (state): boolean => state.isArchivingFile,
}