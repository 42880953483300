import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { MissionTypeResponseDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../../RootState'
import { IMissionTypeState } from './states'


export const getters: GetterTree<IMissionTypeState, RootState> = {
  missionTypesList: (state): MissionTypeResponseDto[] => state.missionTypesList,
  missionTypesListArchived: (state): MissionTypeResponseDto[] => state.missionTypesListArchived,
  missionType     : (state): MissionTypeResponseDto => state.missionType,

  // COMMON
  isLoadingMissionTypesList   : (state): boolean => state.isLoadingMissionTypesList,
  isLoadingMissionTypesListArchived   : (state): boolean => state.isLoadingMissionTypesListArchived,
  isLoadingMissionType   : (state): boolean => state.isLoadingMissionType,
  isCreatingMissionType  : (state): boolean => state.isCreatingMissionType,
  isUpdatingMissionType  : (state): boolean => state.isUpdatingMissionType,
  isDeletingMissionType  : (state): boolean => state.isDeletingMissionType,
  requestStateMissionType: (state): IRequestState => state.requestStateMissionType,
  isOpenSidebarMissionType : (state): boolean => state.isOpenSidebarMissionType,
  isArchivingMissionType : (state): boolean => state.isArchivingMissionType,
}