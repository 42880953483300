import RootState from "@/store/modules/RootState";
import { IInterventionStatisticsState } from "./states";
import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

export const state: IInterventionStatisticsState = {
  statsInterventions: null,
  isLoadingStatsInterventions: false,
};

export const InterventionStatisticsModule: Module<
  IInterventionStatisticsState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
