import { ProductResponseDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "../../rootState";
import { ProductMutations } from "./mutations";
import { IProductState } from "./states";

export const actions: ActionTree<IProductState, RootState> = {
  /**
   * It fetches the list of products from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchProductsList({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(ProductMutations.SET_IS_LOADING_PRODUCTS_LIST, true);
      commit(ProductMutations.SET_IS_LOADING_PRODUCTS_LIST_ARCHIVED, false);
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + "Management/Product/GetProducts",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
          }
        )
        .then((res) => {
          // Réécrire la propriété "price" de chaque élément de res.data avec 2 décimales
          const updatedData = res.data.map((item) => {
            return { ...item, netPrice: item.netPrice.toFixed(2), publicPrice: item.publicPrice.toFixed(2) };
          });

          commit(ProductMutations.SET_PRODUCTS_LIST, updatedData);
          commit(ProductMutations.SET_REQUEST_STATE_PRODUCT, res);
          commit(ProductMutations.SET_IS_LOADING_PRODUCTS_LIST, false);
          resolve(updatedData);
        })
        .catch((err) => {
          console.error(err);
          commit(ProductMutations.SET_REQUEST_STATE_PRODUCT, err);
          commit(ProductMutations.SET_IS_LOADING_PRODUCTS_LIST, false);
        });
    });
  },

  async fetchProduct(
    { commit, rootGetters },
    productId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(ProductMutations.SET_IS_LOADING_PRODUCT, true);
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + "Management/Product/GetProductById",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              productId: productId,
            },
          }
        )
        .then((res) => {
          commit(ProductMutations.SET_PRODUCT, res.data);
          commit(ProductMutations.SET_REQUEST_STATE_PRODUCT, res);
          commit(ProductMutations.SET_IS_LOADING_PRODUCT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ProductMutations.SET_REQUEST_STATE_PRODUCT, err);
          commit(ProductMutations.SET_IS_LOADING_PRODUCT, false);
        });
    });
  },

  /**
   * It fetches the product data from the API.
   * @param  - productId: The productId of the product you want to fetch.
   * @param {string} productId - string
   */

  /**
   * It creates a new product.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {ProductResponseDto} product - ProductResponseDto
   */
  async createProduct(
    { commit, rootGetters },
    product: ProductResponseDto
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(ProductMutations.SET_IS_CREATING_PRODUCT, true);
      axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Management/Product/CreateProduct",
          product,
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
          }
        )
        .then((res) => {
          rootGetters.productsList.unshift(res.data.data);

          commit(ProductMutations.SET_PRODUCTS_LIST, rootGetters.productsList);
          commit(ProductMutations.SET_REQUEST_STATE_PRODUCT, res);
          commit(ProductMutations.SET_IS_CREATING_PRODUCT, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit(ProductMutations.SET_REQUEST_STATE_PRODUCT, err);
          commit(ProductMutations.SET_IS_CREATING_PRODUCT, false);
          reject(err);
        });
    });
  },
  /**
   * Update an product
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {ProductResponseDto} product - ProductResponseDto
   */
  async updateProduct({ commit, rootGetters }, product: ProductResponseDto) {
    commit(ProductMutations.SET_IS_UPDATING_PRODUCT, true);
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/Product/UpdateProduct`,
        { ...product },
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        }
      )
      .then((res) => {
        rootGetters.productsList.splice(
          rootGetters.productsList.findIndex((elem) => elem.id == product.id),
          1,
          product
        );
        commit(ProductMutations.SET_PRODUCTS_LIST, rootGetters.productsList);
        commit(ProductMutations.SET_REQUEST_STATE_PRODUCT, res);
        commit(ProductMutations.SET_IS_UPDATING_PRODUCT, false);
      })
      .catch((err) => {
        commit(ProductMutations.SET_REQUEST_STATE_PRODUCT, err);
        commit(ProductMutations.SET_IS_UPDATING_PRODUCT, false);
      });
  },

  /**
   * It deletes an product from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} productId - string
   */
  async deleteProduct({ commit, rootGetters }, productId: string) {
    commit(ProductMutations.SET_IS_DELETING_PRODUCT, true);
    await axios
      .delete(
        `${process.env.VUE_APP_API_ORISIS}Management/Product/DeleteProduct`,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          params: {
            productId: productId,
          },
        }
      )
      .then((res) => {
        rootGetters.productsListArchived.splice(
          rootGetters.productsListArchived.findIndex(
            (elem) => elem.id == productId
          ),
          1
        );
        commit(ProductMutations.SET_PRODUCTS_LIST, rootGetters.productsList);
        commit(ProductMutations.SET_REQUEST_STATE_PRODUCT, res);
        commit(ProductMutations.SET_IS_DELETING_PRODUCT, false);
      })
      .catch((err) => {
        commit(ProductMutations.SET_REQUEST_STATE_PRODUCT, err);
        commit(ProductMutations.SET_IS_DELETING_PRODUCT, false);
      });
  },

  async archiveProduct (
    { commit, rootGetters },
    productId: string
  ): Promise<void> {
    commit(ProductMutations.SET_IS_ARCHIVING_PRODUCT, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Management/Product/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[productId]
        }
      )
      .then(res => {
        rootGetters.productsListArchived.unshift(rootGetters.productsList.find(elem => elem.id == productId ))
        rootGetters.productsList.splice(
          rootGetters.productsList.findIndex(
            elem => elem.id == productId
          ),
          1
        )
        commit(
          ProductMutations.SET_PRODUCTS_LIST,
          rootGetters.productsList
        )
        commit(ProductMutations.SET_REQUEST_STATE_PRODUCT, res)
        commit(ProductMutations.SET_IS_ARCHIVING_PRODUCT, false)
      })
      .catch(err => {
        console.error(err)
        commit(ProductMutations.SET_REQUEST_STATE_PRODUCT, err)
        commit(ProductMutations.SET_IS_ARCHIVING_PRODUCT, false)
      })
  },

  async restoreProduct (
    { commit, rootGetters },
    productId: string
  ): Promise<void> {
    commit(ProductMutations.SET_IS_UPDATING_PRODUCT, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Management/Product/Restore',
        [productId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.productsList.unshift(rootGetters.productsListArchived.find(elem => elem.id == productId ))
        rootGetters.productsListArchived.splice(
          rootGetters.productsListArchived.findIndex(
            elem => elem.id == productId
          ),
          1
        )
        commit(ProductMutations.SET_REQUEST_STATE_PRODUCT, res)
        commit(ProductMutations.SET_IS_UPDATING_PRODUCT, false)
      })
      .catch(err => {
        console.error(err)
        commit(ProductMutations.SET_REQUEST_STATE_PRODUCT, err)
        commit(ProductMutations.SET_IS_UPDATING_PRODUCT, false)
      })
  },
};
