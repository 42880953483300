import Vue from 'vue';

Vue.directive('async', {
  inserted(el, binding) {
    binding.promise = new Promise(resolve => {
      binding.resolve = resolve;
    });
  },
  async bind(el, binding) {
    try {
      const value = await binding.value;
      el.innerHTML = value;
      binding.resolve(value);
    } catch (error) {
      console.error(error);
    }
  },
});