import { BrandResponseDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { BrandMutations } from './mutations'
import { IBrandState } from './states'

export const actions: ActionTree<IBrandState, RootState> = {
  /**
   * It fetches the list of brands from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchBrandsList ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(BrandMutations.SET_IS_LOADING_BRANDS_LIST, true)
      commit(BrandMutations.SET_IS_LOADING_BRANDS_LIST_ARCHIVED, false)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Management/Brand/GetBrands',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
          }
        )
        .then(res => {
          commit(BrandMutations.SET_BRANDS_LIST, res.data)
          commit(BrandMutations.SET_REQUEST_STATE_BRAND, res)
          commit(BrandMutations.SET_IS_LOADING_BRANDS_LIST, false)
          resolve(res.data)

        })
        .catch(err => {
          console.error(err)
          commit(BrandMutations.SET_REQUEST_STATE_BRAND, err)
          commit(BrandMutations.SET_IS_LOADING_BRANDS_LIST, false)
          reject(err)
        })
    })
  },


  async fetchBrand (
    { commit, rootGetters },
    brandId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
        commit(BrandMutations.SET_IS_LOADING_BRAND, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS + 'Management/Brand/GetBrandById',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                brandId: brandId
              }
            }
          )
          .then(res => {
            commit(BrandMutations.SET_BRAND, res.data)
            commit(BrandMutations.SET_REQUEST_STATE_BRAND, res)
            commit(BrandMutations.SET_IS_LOADING_BRAND, false)
            resolve(res.data)
          })
          .catch(err => {
            console.error(err)
            commit(BrandMutations.SET_REQUEST_STATE_BRAND, err)
            commit(BrandMutations.SET_IS_LOADING_BRAND, false)
            reject(err)
          })
    })
  },
 
  /**
   * It fetches the brand data from the API.
   * @param  - brandId: The brandId of the brand you want to fetch.
   * @param {string} brandId - string
   */ 
  async fetchBrandArchived (
    { commit, rootGetters },
    brandId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(BrandMutations.SET_IS_LOADING_BRAND, true)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Management/Brand/GetBrandArchivedById',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              brandId: brandId
            }
          }
        )
        .then(res => {
          commit(BrandMutations.SET_BRAND, res.data)
          commit(BrandMutations.SET_REQUEST_STATE_BRAND, res)
          commit(BrandMutations.SET_IS_LOADING_BRAND, false)
          resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          commit(BrandMutations.SET_REQUEST_STATE_BRAND, err)
          commit(BrandMutations.SET_IS_LOADING_BRAND, false)
          reject(err)
        })
    })
  },


  /**
   * It creates a new brand.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {BrandResponseDto} brand - BrandResponseDto
   */
   async createBrand ( 
    { commit, rootGetters },
    brand: BrandResponseDto
  ): Promise<void> {

    return await new Promise((resolve, reject) => {
    commit(BrandMutations.SET_IS_CREATING_BRAND, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS + 'Management/Brand/CreateBrand',
        brand,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.brandsList.unshift(res.data.data)

        commit(BrandMutations.SET_BRANDS_LIST, rootGetters.brandsList)
        commit(BrandMutations.SET_REQUEST_STATE_BRAND, res)
        commit(BrandMutations.SET_IS_CREATING_BRAND, false)
        resolve(res.data.data)
      })
      .catch(err => {
        commit(BrandMutations.SET_REQUEST_STATE_BRAND, err)
        commit(BrandMutations.SET_IS_CREATING_BRAND, false)
        reject(err)
      })
    })
  },
  /**
   * Update an brand
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {BrandResponseDto} brand - BrandResponseDto
   */
  async updateBrand ({ commit, rootGetters }, brand: BrandResponseDto) {
    commit(BrandMutations.SET_IS_UPDATING_BRAND, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/Brand/UpdateBrand`,
        {...brand},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.brandsList.splice(
          rootGetters.brandsList.findIndex(elem => elem.id == brand.id),
          1,
          brand
        )
        commit(BrandMutations.SET_BRANDS_LIST, rootGetters.brandsList)
        commit(BrandMutations.SET_REQUEST_STATE_BRAND, res)
        commit(BrandMutations.SET_IS_UPDATING_BRAND, false)
      })
      .catch(err => {
        commit(BrandMutations.SET_REQUEST_STATE_BRAND, err)
        commit(BrandMutations.SET_IS_UPDATING_BRAND, false)
      })
  },

  /**
   * It deletes an brand from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} brandId - string
   */
  async deleteBrand ({ commit, rootGetters }, brandId: string) {
    commit(BrandMutations.SET_IS_DELETING_BRAND, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Management/Brand/DeleteBrand`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          brandId: brandId
        }
      })
      .then(res => {
        rootGetters.brandsListArchived.splice(
          rootGetters.brandsListArchived.findIndex(elem => elem.id == brandId),
          1
        )
        commit(BrandMutations.SET_BRANDS_LIST, rootGetters.brandsList)
        commit(BrandMutations.SET_REQUEST_STATE_BRAND, res)
        commit(BrandMutations.SET_IS_DELETING_BRAND, false)
      })
      .catch(err => {
        commit(BrandMutations.SET_REQUEST_STATE_BRAND, err)
        commit(BrandMutations.SET_IS_DELETING_BRAND, false)
      })
  },

  async archiveBrand (
    { commit, rootGetters },
    brandId: string
  ): Promise<void> {
    commit(BrandMutations.SET_IS_ARCHIVING_BRAND, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Management/Brand/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[brandId]
        }
      )
      .then(res => {
        rootGetters.brandsListArchived.unshift(rootGetters.brandsList.find(elem => elem.id == brandId ))
        rootGetters.brandsList.splice(
          rootGetters.brandsList.findIndex(
            elem => elem.id == brandId
          ),
          1
        )
        commit(
          BrandMutations.SET_BRANDS_LIST,
          rootGetters.brandsList
        )
        commit(BrandMutations.SET_REQUEST_STATE_BRAND, res)
        commit(BrandMutations.SET_IS_ARCHIVING_BRAND, false)
      })
      .catch(err => {
        console.error(err)
        commit(BrandMutations.SET_REQUEST_STATE_BRAND, err)
        commit(BrandMutations.SET_IS_ARCHIVING_BRAND, false)
      })
  },

  async restoreBrand (
    { commit, rootGetters },
    brandId: string
  ): Promise<void> {
    commit(BrandMutations.SET_IS_UPDATING_BRAND, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Management/Brand/Restore',
        [brandId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.brandsList.unshift(rootGetters.brandsListArchived.find(elem => elem.id == brandId ))
        rootGetters.brandsListArchived.splice(
          rootGetters.brandsListArchived.findIndex(
            elem => elem.id == brandId
          ),
          1
        )
        commit(BrandMutations.SET_REQUEST_STATE_BRAND, res)
        commit(BrandMutations.SET_IS_UPDATING_BRAND, false)
      })
      .catch(err => {
        console.error(err)
        commit(BrandMutations.SET_REQUEST_STATE_BRAND, err)
        commit(BrandMutations.SET_IS_UPDATING_BRAND, false)
      })
  },
}
