import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ProductUsedResponseDto } from "@/../Api";
import { MutationTree } from "vuex";
import { IProductUsedState } from "./states";

export enum ProductUsedMutations {
  SET_PRODUCT_USED_LIST = "SET_PRODUCTS_USED_LIST",
  // SET_PRODUCT_USED_LIST = "SET_PRODUCTS_LIST",
  SET_PRODUCT_USED = "SET_PRODUCT_USED",
  // SET_PRODUCT_USED = "SET_PRODUCT",

  // COMMON
  SET_IS_LOADING_PRODUCT_USED_LIST = "SET_IS_LOADING_PRODUCTS_LIST",

  SET_IS_LOADING_PRODUCT_USED = "SET_IS_LOADING_PRODUCT_USED",
  SET_IS_CREATING_PRODUCT_USED = "SET_IS_CREATING_PRODUCT_USED",
  SET_IS_UPDATING_PRODUCT_USED = "SET_IS_UPDATING_PRODUCT_USED",
  SET_IS_DELETING_PRODUCT_USED = "SET_IS_DELETING_PRODUCT_USED",
  SET_REQUEST_STATE_PRODUCT_USED = "SET_REQUEST_STATE_PRODUCT_USED",
  SET_IS_OPEN_SIDEBAR_PRODUCT_USED = "SET_IS_OPEN_SIDEBAR_PRODUCT_USED",
}

export const mutations: MutationTree<IProductUsedState> = {
  [ProductUsedMutations.SET_PRODUCT_USED_LIST]: (
    state,
    payload: ProductUsedResponseDto[]
  ) => {
    state.productUsedList = payload;
  },
  [ProductUsedMutations.SET_PRODUCT_USED]: (
    state,
    payload: ProductUsedResponseDto
  ) => {
    state.productUsed = payload;
  },

  //COMMON
  [ProductUsedMutations.SET_IS_LOADING_PRODUCT_USED_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingProductUsedList = payload;
  },
  [ProductUsedMutations.SET_IS_LOADING_PRODUCT_USED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingProductUsed = payload;
  },
  [ProductUsedMutations.SET_IS_CREATING_PRODUCT_USED]: (
    state,
    payload: boolean
  ) => {
    state.isCreatingProductUsed = payload;
  },
  [ProductUsedMutations.SET_IS_UPDATING_PRODUCT_USED]: (
    state,
    payload: boolean
  ) => {
    state.isUpdatingProductUsed = payload;
  },
  [ProductUsedMutations.SET_IS_DELETING_PRODUCT_USED]: (
    state,
    payload: boolean
  ) => {
    state.isDeletingProductUsed = payload;
  },
  [ProductUsedMutations.SET_REQUEST_STATE_PRODUCT_USED]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateProductUsed = payload;
  },
  [ProductUsedMutations.SET_IS_OPEN_SIDEBAR_PRODUCT_USED]: (
    state,
    payload: boolean
  ) => {
    state.isOpenSidebarProductUsed = payload;
  },
};
