import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { StepResponseDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IStepState } from "./states";

export enum StepMutations {
    SET_STEPS_LIST = "SET_STEPS_LIST",
    SET_COMMON_STEPS_LIST = "SET_COMMON_STEPS_LIST",
    SET_STEPS_LIST_ARCHIVED = "SET_STEPS_LIST_ARCHIVED",
    SET_COMMON_STEPS_LIST_ARCHIVED = "SET_COMMON_STEPS_LIST_ARCHIVED",
    SET_STEP = "SET_STEP",

    // COMMON
    SET_IS_LOADING_STEPS_LIST= "SET_IS_LOADING_STEPS_LIST",
    SET_IS_LOADING_COMMON_STEPS_LIST= "SET_IS_LOADING_COMMON_STEPS_LIST",
    SET_IS_LOADING_STEPS_LIST_ARCHIVED= "SET_IS_LOADING_STEPS_LIST_ARCHIVED",
    SET_IS_LOADING_COMMON_STEPS_LIST_ARCHIVED= "SET_IS_LOADING_COMMON_STEPS_LIST_ARCHIVED",
    
    SET_IS_LOADING_STEP= "SET_IS_LOADING_STEP",
    SET_IS_CREATING_STEP = "SET_IS_CREATING_STEP",
    SET_IS_UPDATING_STEP= "SET_IS_UPDATING_STEP",
    SET_IS_UPDATING_MISSION_STEP= "SET_IS_UPDATING_MISSION_STEP",
    SET_IS_UPDATING_MISSION_STEP_INTERVENTION= "SET_IS_UPDATING_MISSION_STEP_INTERVENTION",
    SET_IS_DELETING_STEP = "SET_IS_DELETING_STEP",
    SET_REQUEST_STATE_STEP = "SET_REQUEST_STATE_STEP",
    SET_REQUEST_STATE_COMMON_STEP = "SET_REQUEST_STATE_COMMON_STEP",
    SET_IS_OPEN_SIDEBAR_STEP = "SET_IS_OPEN_SIDEBAR_STEP",
    SET_IS_ARCHIVING_STEP = "SET_IS_ARCHIVING_STEP"
}

export const mutations: MutationTree<IStepState> = {
    [StepMutations.SET_STEPS_LIST]: (state, payload: StepResponseDto[]) => {
        state.stepsList = payload;
    },
    [StepMutations.SET_COMMON_STEPS_LIST]: (state, payload: StepResponseDto[]) => {
        state.commonStepsList = payload;
    },
    [StepMutations.SET_STEPS_LIST_ARCHIVED]: (state, payload: StepResponseDto[]) => {
        state.stepsListArchived = payload;
    },
    [StepMutations.SET_COMMON_STEPS_LIST_ARCHIVED]: (state, payload: StepResponseDto[]) => {
        state.commonStepsListArchived = payload;
    },
    [StepMutations.SET_STEP]: (state, payload: StepResponseDto) => {
        state.step = payload;
    },

    //COMMON
    [StepMutations.SET_IS_LOADING_STEPS_LIST]: (state, payload: boolean) => {
        state.isLoadingStepsList = payload;
    },
    [StepMutations.SET_IS_LOADING_COMMON_STEPS_LIST]: (state, payload: boolean) => {
        state.isLoadingCommonStepsList = payload;
    },
    [StepMutations.SET_IS_LOADING_STEPS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingStepsListArchived = payload;
    },
    [StepMutations.SET_IS_LOADING_COMMON_STEPS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingCommonStepsListArchived = payload;
    },
    [StepMutations.SET_IS_LOADING_STEP]: (state, payload: boolean) => {
        state.isLoadingStep = payload;
    },
    [StepMutations.SET_IS_CREATING_STEP]: (state, payload: boolean) => {
        state.isCreatingStep = payload;
    },
    [StepMutations.SET_IS_UPDATING_STEP]: (state, payload: boolean) => {
        state.isUpdatingStep = payload;
    },
    [StepMutations.SET_IS_UPDATING_MISSION_STEP]: (state, payload: boolean) => {
        state.isUpdatingMissionStep = payload;
    },
    [StepMutations.SET_IS_UPDATING_MISSION_STEP_INTERVENTION]: (state, payload: boolean) => {
        state.isUpdatingMissionStepIntervention = payload;
    },
    [StepMutations.SET_IS_DELETING_STEP]: (state, payload: boolean) => {
        state.isDeletingStep = payload;
    },
    [StepMutations.SET_REQUEST_STATE_STEP]: (state, payload: IRequestState) => {
        state.requestStateStep = payload;
    },
    [StepMutations.SET_REQUEST_STATE_COMMON_STEP]: (state, payload: IRequestState) => {
        state.requestStateCommonStep = payload;
    },
    [StepMutations.SET_IS_OPEN_SIDEBAR_STEP]: (state, payload: boolean) => {
        state.isOpenSidebarStep = payload;
    },
    [StepMutations.SET_IS_ARCHIVING_STEP]: (state, payload: boolean) => {
        state.isArchivingStep = payload;
    },
}