import { ContractResponseDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../../RootState'
import { ProfessionalContractTypeMutations } from './mutations'
import { IProfessionalContractTypeState } from './states'

export const actions: ActionTree<IProfessionalContractTypeState, RootState> = {
  /**
   * It fetches the list of professionalContractTypes from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchProfessionalContractTypesList ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(ProfessionalContractTypeMutations.SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPES_LIST, true)
      commit(ProfessionalContractTypeMutations.SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPES_LIST_ARCHIVED, false)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Parameters/Contract/GetContracts',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
          }
        )
        .then(res => {
          commit(ProfessionalContractTypeMutations.SET_PROFESSIONAL_CONTRACT_TYPES_LIST, res.data)
          commit(ProfessionalContractTypeMutations.SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE, res)
          commit(ProfessionalContractTypeMutations.SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPES_LIST, false)
          resolve(res.data)

        })
        .catch(err => {
          console.error(err)
          commit(ProfessionalContractTypeMutations.SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE, err)
          commit(ProfessionalContractTypeMutations.SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPES_LIST, false)
          reject(err)
        })
    })
  },

  async fetchProfessionalContractTypesListArchived ({ commit, rootGetters }): Promise<void> {
    commit(ProfessionalContractTypeMutations.SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPES_LIST_ARCHIVED, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Management/ProfessionalContractType/GetProfessionalContractTypesArchived',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
        }
      )
      .then(res => {
        commit(ProfessionalContractTypeMutations.SET_PROFESSIONAL_CONTRACT_TYPES_LIST_ARCHIVED, res.data)
        commit(ProfessionalContractTypeMutations.SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE, res)
        commit(ProfessionalContractTypeMutations.SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPES_LIST_ARCHIVED, false)
      })
      .catch(err => {
        console.error(err)
        commit(ProfessionalContractTypeMutations.SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE, err)
        commit(ProfessionalContractTypeMutations.SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPES_LIST_ARCHIVED, false)
      })
  },

  async fetchProfessionalContractType (
    { commit, rootGetters },
    professionalContractTypeId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
        commit(ProfessionalContractTypeMutations.SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPE, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS + 'Parameters/Contract/GetContractById',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                contractId: professionalContractTypeId
              }
            }
          )
          .then(res => {
            commit(ProfessionalContractTypeMutations.SET_PROFESSIONAL_CONTRACT_TYPE, res.data)
            commit(ProfessionalContractTypeMutations.SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE, res)
            commit(ProfessionalContractTypeMutations.SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPE, false)
            resolve(res.data)
          })
          .catch(err => {
            console.error(err)
            commit(ProfessionalContractTypeMutations.SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE, err)
            commit(ProfessionalContractTypeMutations.SET_IS_LOADING_PROFESSIONAL_CONTRACT_TYPE, false)
            reject(err)
          })
    })
  },
  /**
   * It creates a new professionalContractType.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {ContractResponseDto} professionalContractType - ContractResponseDto
   */
   async createProfessionalContractType ( 
    { commit, rootGetters },
    professionalContractType: ContractResponseDto
  ): Promise<void> {

    return await new Promise((resolve, reject) => {
    commit(ProfessionalContractTypeMutations.SET_IS_CREATING_PROFESSIONAL_CONTRACT_TYPE, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS + 'Parameters/Contract/CreateContract',
        professionalContractType,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.professionalContractTypesList.unshift(res.data.data)

        commit(ProfessionalContractTypeMutations.SET_PROFESSIONAL_CONTRACT_TYPES_LIST, rootGetters.professionalContractTypesList)
        commit(ProfessionalContractTypeMutations.SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE, res)
        commit(ProfessionalContractTypeMutations.SET_IS_CREATING_PROFESSIONAL_CONTRACT_TYPE, false)
        resolve(res.data.data)
      })
      .catch(err => {
        commit(ProfessionalContractTypeMutations.SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE, err)
        commit(ProfessionalContractTypeMutations.SET_IS_CREATING_PROFESSIONAL_CONTRACT_TYPE, false)
        reject(err)
      })
    })
  },
  /**
   * Update an professionalContractType
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {ContractResponseDto} professionalContractType - ContractResponseDto
   */
  async updateProfessionalContractType ({ commit, rootGetters }, professionalContractType: ContractResponseDto) {
    commit(ProfessionalContractTypeMutations.SET_IS_UPDATING_PROFESSIONAL_CONTRACT_TYPE, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Parameters/Contract/UpdateContract`,
        {...professionalContractType},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.professionalContractTypesList.splice(
          rootGetters.professionalContractTypesList.findIndex(elem => elem.id == professionalContractType.id),
          1,
          professionalContractType
        )
        commit(ProfessionalContractTypeMutations.SET_PROFESSIONAL_CONTRACT_TYPES_LIST, rootGetters.professionalContractTypesList)
        commit(ProfessionalContractTypeMutations.SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE, res)
        commit(ProfessionalContractTypeMutations.SET_IS_UPDATING_PROFESSIONAL_CONTRACT_TYPE, false)
      })
      .catch(err => {
        commit(ProfessionalContractTypeMutations.SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE, err)
        commit(ProfessionalContractTypeMutations.SET_IS_UPDATING_PROFESSIONAL_CONTRACT_TYPE, false)
      })
  },

  /**
   * It deletes an professionalContractType from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} professionalContractTypeId - string
   */
  async deleteProfessionalContractType ({ commit, rootGetters }, professionalContractTypeId: string) {
    commit(ProfessionalContractTypeMutations.SET_IS_DELETING_PROFESSIONAL_CONTRACT_TYPE, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Parameters/Contract/Delete`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          contractId: professionalContractTypeId
        }
      })
      .then(res => {
        rootGetters.professionalContractTypesListArchived.splice(
          rootGetters.professionalContractTypesListArchived.findIndex(elem => elem.id == professionalContractTypeId),
          1
        )
        commit(ProfessionalContractTypeMutations.SET_PROFESSIONAL_CONTRACT_TYPES_LIST, rootGetters.professionalContractTypesList)
        commit(ProfessionalContractTypeMutations.SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE, res)
        commit(ProfessionalContractTypeMutations.SET_IS_DELETING_PROFESSIONAL_CONTRACT_TYPE, false)
      })
      .catch(err => {
        commit(ProfessionalContractTypeMutations.SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE, err)
        commit(ProfessionalContractTypeMutations.SET_IS_DELETING_PROFESSIONAL_CONTRACT_TYPE, false)
      })
  },

  async archiveProfessionalContractType (
    { commit, rootGetters },
    professionalContractTypeId: string
  ): Promise<void> {
    commit(ProfessionalContractTypeMutations.SET_IS_ARCHIVING_PROFESSIONAL_CONTRACT_TYPE, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Parameters/Contract/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[professionalContractTypeId]
        }
      )
      .then(res => {
        rootGetters.professionalContractTypesListArchived.unshift(rootGetters.professionalContractTypesList.find(elem => elem.id == professionalContractTypeId ))
        rootGetters.professionalContractTypesList.splice(
          rootGetters.professionalContractTypesList.findIndex(
            elem => elem.id == professionalContractTypeId
          ),
          1
        )
        commit(
          ProfessionalContractTypeMutations.SET_PROFESSIONAL_CONTRACT_TYPES_LIST,
          rootGetters.professionalContractTypesList
        )
        commit(ProfessionalContractTypeMutations.SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE, res)
        commit(ProfessionalContractTypeMutations.SET_IS_ARCHIVING_PROFESSIONAL_CONTRACT_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(ProfessionalContractTypeMutations.SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE, err)
        commit(ProfessionalContractTypeMutations.SET_IS_ARCHIVING_PROFESSIONAL_CONTRACT_TYPE, false)
      })
   },

   async restoreProfessionalContractType (
    { commit, rootGetters },
    professionalContractTypeId: string
  ): Promise<void> {
    commit(ProfessionalContractTypeMutations.SET_IS_UPDATING_PROFESSIONAL_CONTRACT_TYPE, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Parameters/Contract/Restore',
        [professionalContractTypeId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.professionalContractTypesList.unshift(rootGetters.professionalContractTypesListArchived.find(elem => elem.id == professionalContractTypeId ))
        rootGetters.professionalContractTypesListArchived.splice(
          rootGetters.professionalContractTypesListArchived.findIndex(
            elem => elem.id == professionalContractTypeId
          ),
          1
        )
        commit(ProfessionalContractTypeMutations.SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE, res)
        commit(ProfessionalContractTypeMutations.SET_IS_UPDATING_PROFESSIONAL_CONTRACT_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(ProfessionalContractTypeMutations.SET_REQUEST_STATE_PROFESSIONAL_CONTRACT_TYPE, err)
        commit(ProfessionalContractTypeMutations.SET_IS_UPDATING_PROFESSIONAL_CONTRACT_TYPE, false)
      })
   }
}
