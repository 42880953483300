import { ContractTypeResponseDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../../RootState'
import { ContractMaintenanceTypeMutations } from './mutations'
import { IContractMaintenanceTypeState } from './states'

export const actions: ActionTree<IContractMaintenanceTypeState, RootState> = {
  /**
   * It fetches the list of contractMaintenanceTypes from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchContractMaintenanceTypesList ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(ContractMaintenanceTypeMutations.SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPES_LIST, true)
      commit(ContractMaintenanceTypeMutations.SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPES_LIST_ARCHIVED, false)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Management/ContractType/GetContractTypes',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
          }
        )
        .then(res => {
          commit(ContractMaintenanceTypeMutations.SET_CONTRACT_MAINTENANCE_TYPES_LIST, res.data)
          commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, res)
          commit(ContractMaintenanceTypeMutations.SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPES_LIST, false)
          resolve(res.data)

        })
        .catch(err => {
          console.error(err)
          commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, err)
          commit(ContractMaintenanceTypeMutations.SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPES_LIST, false)
          reject(err)
        })
    })
  },

  async fetchContractMaintenanceTypesListArchived ({ commit, rootGetters }): Promise<void> {
    commit(ContractMaintenanceTypeMutations.SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPES_LIST_ARCHIVED, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Management/ContractMaintenanceType/GetContractMaintenanceTypesArchived',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
        }
      )
      .then(res => {
        commit(ContractMaintenanceTypeMutations.SET_CONTRACT_MAINTENANCE_TYPES_LIST_ARCHIVED, res.data)
        commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, res)
        commit(ContractMaintenanceTypeMutations.SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPES_LIST_ARCHIVED, false)
      })
      .catch(err => {
        console.error(err)
        commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, err)
        commit(ContractMaintenanceTypeMutations.SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPES_LIST_ARCHIVED, false)
      })
  },

  async fetchContractMaintenanceType (
    { commit, rootGetters },
    contractMaintenanceTypeId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
        commit(ContractMaintenanceTypeMutations.SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPE, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS + 'Management/ContractType/GetContractTypeById',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                contractTypeId: contractMaintenanceTypeId
              }
            }
          )
          .then(res => {
            commit(ContractMaintenanceTypeMutations.SET_CONTRACT_MAINTENANCE_TYPE, res.data)
            commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, res)
            commit(ContractMaintenanceTypeMutations.SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPE, false)
            resolve(res.data)
          })
          .catch(err => {
            console.error(err)
            commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, err)
            commit(ContractMaintenanceTypeMutations.SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPE, false)
            reject(err)
          })
    })
  },
 
  /**
   * It fetches the contractMaintenanceType data from the API.
   * @param  - contractMaintenanceTypeId: The contractMaintenanceTypeId of the contractMaintenanceType you want to fetch.
   * @param {string} contractMaintenanceTypeId - string
   */ 
  async fetchContractMaintenanceTypeArchived (
    { commit, rootGetters },
    contractMaintenanceTypeId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(ContractMaintenanceTypeMutations.SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPE, true)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Management/ContractType/GetContractTypeArchivedById',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              contractTypeId: contractMaintenanceTypeId
            }
          }
        )
        .then(res => {
          commit(ContractMaintenanceTypeMutations.SET_CONTRACT_MAINTENANCE_TYPE, res.data)
          commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, res)
          commit(ContractMaintenanceTypeMutations.SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPE, false)
          resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, err)
          commit(ContractMaintenanceTypeMutations.SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPE, false)
          reject(err)
        })
    })
  },


  /**
   * It creates a new contractMaintenanceType.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {ContractTypeResponseDto} contractMaintenanceType - ContractTypeResponseDto
   */
   async createContractMaintenanceType ( 
    { commit, rootGetters },
    contractMaintenanceType: ContractTypeResponseDto
  ): Promise<void> {

    return await new Promise((resolve, reject) => {
    commit(ContractMaintenanceTypeMutations.SET_IS_CREATING_CONTRACT_MAINTENANCE_TYPE, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS + 'Management/ContractType/CreateContractType',
        contractMaintenanceType,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.contractMaintenanceTypesList.unshift(res.data.data)

        commit(ContractMaintenanceTypeMutations.SET_CONTRACT_MAINTENANCE_TYPES_LIST, rootGetters.contractMaintenanceTypesList)
        commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, res)
        commit(ContractMaintenanceTypeMutations.SET_IS_CREATING_CONTRACT_MAINTENANCE_TYPE, false)
        resolve(res.data.data)
      })
      .catch(err => {
        commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, err)
        commit(ContractMaintenanceTypeMutations.SET_IS_CREATING_CONTRACT_MAINTENANCE_TYPE, false)
        reject(err)
      })
    })
  },
  /**
   * Update an contractMaintenanceType
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {ContractTypeResponseDto} contractMaintenanceType - ContractTypeResponseDto
   */
  async updateContractMaintenanceType ({ commit, rootGetters }, contractMaintenanceType: ContractTypeResponseDto) {
    commit(ContractMaintenanceTypeMutations.SET_IS_UPDATING_CONTRACT_MAINTENANCE_TYPE, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/ContractType/UpdateContractType`,
        {...contractMaintenanceType},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.contractMaintenanceTypesList.splice(
          rootGetters.contractMaintenanceTypesList.findIndex(elem => elem.id == contractMaintenanceType.id),
          1,
          contractMaintenanceType
        )
        commit(ContractMaintenanceTypeMutations.SET_CONTRACT_MAINTENANCE_TYPES_LIST, rootGetters.contractMaintenanceTypesList)
        commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, res)
        commit(ContractMaintenanceTypeMutations.SET_IS_UPDATING_CONTRACT_MAINTENANCE_TYPE, false)
      })
      .catch(err => {
        commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, err)
        commit(ContractMaintenanceTypeMutations.SET_IS_UPDATING_CONTRACT_MAINTENANCE_TYPE, false)
      })
  },

  /**
   * It deletes an contractMaintenanceType from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} contractMaintenanceTypeId - string
   */
  async deleteContractMaintenanceType ({ commit, rootGetters }, contractMaintenanceTypeId: string) {
    commit(ContractMaintenanceTypeMutations.SET_IS_DELETING_CONTRACT_MAINTENANCE_TYPE, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Management/ContractType/DeleteContractType`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          contractTypeId: contractMaintenanceTypeId
        }
      })
      .then(res => {
        rootGetters.contractMaintenanceTypesListArchived.splice(
          rootGetters.contractMaintenanceTypesListArchived.findIndex(elem => elem.id == contractMaintenanceTypeId),
          1
        )
        commit(ContractMaintenanceTypeMutations.SET_CONTRACT_MAINTENANCE_TYPES_LIST, rootGetters.contractMaintenanceTypesList)
        commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, res)
        commit(ContractMaintenanceTypeMutations.SET_IS_DELETING_CONTRACT_MAINTENANCE_TYPE, false)
      })
      .catch(err => {
        commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, err)
        commit(ContractMaintenanceTypeMutations.SET_IS_DELETING_CONTRACT_MAINTENANCE_TYPE, false)
      })
  },

  async archiveContractMaintenanceType (
    { commit, rootGetters },
    contractMaintenanceTypeId: string
  ): Promise<void> {
    commit(ContractMaintenanceTypeMutations.SET_IS_ARCHIVING_CONTRACT_MAINTENANCE_TYPE, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Management/ContractType/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[contractMaintenanceTypeId]
        }
      )
      .then(res => {
        rootGetters.contractMaintenanceTypesListArchived.unshift(rootGetters.contractMaintenanceTypesList.find(elem => elem.id == contractMaintenanceTypeId ))
        rootGetters.contractMaintenanceTypesList.splice(
          rootGetters.contractMaintenanceTypesList.findIndex(
            elem => elem.id == contractMaintenanceTypeId
          ),
          1
        )
        commit(
          ContractMaintenanceTypeMutations.SET_CONTRACT_MAINTENANCE_TYPES_LIST,
          rootGetters.contractMaintenanceTypesList
        )
        commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, res)
        commit(ContractMaintenanceTypeMutations.SET_IS_ARCHIVING_CONTRACT_MAINTENANCE_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, err)
        commit(ContractMaintenanceTypeMutations.SET_IS_ARCHIVING_CONTRACT_MAINTENANCE_TYPE, false)
      })
  },

  async restoreContractMaintenanceType (
    { commit, rootGetters },
    contractMaintenanceTypeId: string
  ): Promise<void> {
    commit(ContractMaintenanceTypeMutations.SET_IS_UPDATING_CONTRACT_MAINTENANCE_TYPE, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Management/ContractType/Restore',
        [contractMaintenanceTypeId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.contractMaintenanceTypesList.unshift(rootGetters.contractMaintenanceTypesListArchived.find(elem => elem.id == contractMaintenanceTypeId ))
        rootGetters.contractMaintenanceTypesListArchived.splice(
          rootGetters.contractMaintenanceTypesListArchived.findIndex(
            elem => elem.id == contractMaintenanceTypeId
          ),
          1
        )
        commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, res)
        commit(ContractMaintenanceTypeMutations.SET_IS_UPDATING_CONTRACT_MAINTENANCE_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE, err)
        commit(ContractMaintenanceTypeMutations.SET_IS_UPDATING_CONTRACT_MAINTENANCE_TYPE, false)
      })
  },
}
