import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { CustomerResponseDto } from '@/../Api'
import { MutationTree } from "vuex";
import { ICustomerState } from "./states";

export enum CustomerMutations {
    SET_CUSTOMERS_LIST = "SET_CUSTOMERS_LIST",
    SET_CUSTOMERS_LIST_ARCHIVED = "SET_CUSTOMERS_LIST_ARCHIVED",
    SET_CUSTOMER = "SET_CUSTOMER",

    // COMMON
    SET_IS_LOADING_CUSTOMERS_LIST= "SET_IS_LOADING_CUSTOMERS_LIST",
    SET_IS_LOADING_CUSTOMERS_LIST_ARCHIVED= "SET_IS_LOADING_CUSTOMERS_LIST_ARCHIVED",
    
    SET_IS_LOADING_CUSTOMER= "SET_IS_LOADING_CUSTOMER",
    SET_IS_CREATING_CUSTOMER = "SET_IS_CREATING_CUSTOMER",
    SET_IS_UPDATING_CUSTOMER= "SET_IS_UPDATING_CUSTOMER",
    SET_IS_DELETING_CUSTOMER = "SET_IS_DELETING_CUSTOMER",
    SET_REQUEST_STATE_CUSTOMER = "SET_REQUEST_STATE_CUSTOMER",
    SET_IS_OPEN_SIDEBAR_CUSTOMER = "SET_IS_OPEN_SIDEBAR_CUSTOMER",
    SET_IS_ARCHIVING_CUSTOMER = "SET_IS_ARCHIVING_CUSTOMER"
}

export const mutations: MutationTree<ICustomerState> = {
    [CustomerMutations.SET_CUSTOMERS_LIST]: (state, payload: CustomerResponseDto[]) => {
        state.customersList = payload;
    },
    [CustomerMutations.SET_CUSTOMERS_LIST_ARCHIVED]: (state, payload: CustomerResponseDto[]) => {
        state.customersListArchived = payload;
    },
    [CustomerMutations.SET_CUSTOMER]: (state, payload: CustomerResponseDto) => {
        state.customer = payload;
    },


    //COMMON
    [CustomerMutations.SET_IS_LOADING_CUSTOMERS_LIST]: (state, payload: boolean) => {
        state.isLoadingCustomersList = payload;
    },
    [CustomerMutations.SET_IS_LOADING_CUSTOMERS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingCustomersListArchived = payload;
    },
    [CustomerMutations.SET_IS_LOADING_CUSTOMER]: (state, payload: boolean) => {
        state.isLoadingCustomer = payload;
    },
    [CustomerMutations.SET_IS_CREATING_CUSTOMER]: (state, payload: boolean) => {
        state.isCreatingCustomer = payload;
    },
    [CustomerMutations.SET_IS_UPDATING_CUSTOMER]: (state, payload: boolean) => {
        state.isUpdatingCustomer = payload;
    },
    [CustomerMutations.SET_IS_DELETING_CUSTOMER]: (state, payload: boolean) => {
        state.isDeletingCustomer = payload;
    },
    [CustomerMutations.SET_REQUEST_STATE_CUSTOMER]: (state, payload: IRequestState) => {
        state.requestStateCustomer = payload;
    },
    [CustomerMutations.SET_IS_OPEN_SIDEBAR_CUSTOMER]: (state, payload: boolean) => {
        state.isOpenSidebarCustomer = payload;
    },
    [CustomerMutations.SET_IS_ARCHIVING_CUSTOMER]: (state, payload: boolean) => {
        state.isArchivingCustomer = payload;
    },
}