import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { AnalysisResponseDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "../../RootState";
import { IAnalysisState } from "./states";

export const getters: GetterTree<IAnalysisState, RootState> = {
  analysisList: (state): AnalysisResponseDto[] => state.analysisList,
  analysisListArchived: (state): AnalysisResponseDto[] => state.analysisListArchived,
  analysis: (state): AnalysisResponseDto => state.analysis,

  // COMMON
  isLoadingAnalysisList: (state): boolean => state.isLoadingAnalysisList,
  isLoadingAnalysisListArchived: (state): boolean => state.isLoadingAnalysisListArchived,
  isLoadingAnalysis: (state): boolean => state.isLoadingAnalysis,
  isCreatingAnalysis: (state): boolean => state.isCreatingAnalysis,
  isUpdatingAnalysis: (state): boolean => state.isUpdatingAnalysis,
  isDeletingAnalysis: (state): boolean => state.isDeletingAnalysis,
  requestStateAnalysis: (state): IRequestState => state.requestStateAnalysis,
  isOpenSidebarAnalysis: (state): boolean => state.isOpenSidebarAnalysis,
  isArchivingAnalysis: (state): boolean => state.isArchivingAnalysis,
};
