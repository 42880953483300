import { AccessMethodTypeResponseDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../../RootState'
import { AccessMethodTypeMutations } from './mutations'
import { IAccessMethodTypeState } from './states'

export const actions: ActionTree<IAccessMethodTypeState, RootState> = {
  /**
   * It fetches the list of accessMethodTypes from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchAccessMethodTypesList ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(AccessMethodTypeMutations.SET_IS_LOADING_ACCESS_METHOD_TYPES_LIST, true)
      commit(AccessMethodTypeMutations.SET_IS_LOADING_ACCESS_METHOD_TYPES_LIST_ARCHIVED, false)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Management/AccessMethodType/GetAccessMethodTypes',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
          }
        )
        .then(res => {
          commit(AccessMethodTypeMutations.SET_ACCESS_METHOD_TYPES_LIST, res.data)
          commit(AccessMethodTypeMutations.SET_REQUEST_STATE_ACCESS_METHOD_TYPE, res)
          commit(AccessMethodTypeMutations.SET_IS_LOADING_ACCESS_METHOD_TYPES_LIST, false)
          resolve(res.data)

        })
        .catch(err => {
          console.error(err)
          commit(AccessMethodTypeMutations.SET_REQUEST_STATE_ACCESS_METHOD_TYPE, err)
          commit(AccessMethodTypeMutations.SET_IS_LOADING_ACCESS_METHOD_TYPES_LIST, false)
          reject(err)
        })
    })
  },

  async fetchAccessMethodTypesListArchived ({ commit, rootGetters }): Promise<void> {
    commit(AccessMethodTypeMutations.SET_IS_LOADING_ACCESS_METHOD_TYPES_LIST_ARCHIVED, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Management/AccessMethodType/GetAccessMethodTypesArchived',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
        }
      )
      .then(res => {
        commit(AccessMethodTypeMutations.SET_ACCESS_METHOD_TYPES_LIST_ARCHIVED, res.data)
        commit(AccessMethodTypeMutations.SET_REQUEST_STATE_ACCESS_METHOD_TYPE, res)
        commit(AccessMethodTypeMutations.SET_IS_LOADING_ACCESS_METHOD_TYPES_LIST_ARCHIVED, false)
      })
      .catch(err => {
        console.error(err)
        commit(AccessMethodTypeMutations.SET_REQUEST_STATE_ACCESS_METHOD_TYPE, err)
        commit(AccessMethodTypeMutations.SET_IS_LOADING_ACCESS_METHOD_TYPES_LIST_ARCHIVED, false)
      })
  },

  async fetchAccessMethodType (
    { commit, rootGetters },
    accessMethodTypeId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
        commit(AccessMethodTypeMutations.SET_IS_LOADING_ACCESS_METHOD_TYPE, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS + 'Management/AccessMethodType/GetAccessMethodTypeById',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                accessMethodTypeId: accessMethodTypeId
              }
            }
          )
          .then(res => {
            commit(AccessMethodTypeMutations.SET_ACCESS_METHOD_TYPE, res.data)
            commit(AccessMethodTypeMutations.SET_REQUEST_STATE_ACCESS_METHOD_TYPE, res)
            commit(AccessMethodTypeMutations.SET_IS_LOADING_ACCESS_METHOD_TYPE, false)
            resolve(res.data)
          })
          .catch(err => {
            console.error(err)
            commit(AccessMethodTypeMutations.SET_REQUEST_STATE_ACCESS_METHOD_TYPE, err)
            commit(AccessMethodTypeMutations.SET_IS_LOADING_ACCESS_METHOD_TYPE, false)
            reject(err)
          })
    })
  },
 
  /**
   * It fetches the accessMethodType data from the API.
   * @param  - accessMethodTypeId: The accessMethodTypeId of the accessMethodType you want to fetch.
   * @param {string} accessMethodTypeId - string
   */ 


  /**
   * It creates a new accessMethodType.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {AccessMethodTypeResponseDto} accessMethodType - AccessMethodTypeResponseDto
   */
   async createAccessMethodType ( 
    { commit, rootGetters },
    accessMethodType: AccessMethodTypeResponseDto
  ): Promise<void> {

    return await new Promise((resolve, reject) => {
    commit(AccessMethodTypeMutations.SET_IS_CREATING_ACCESS_METHOD_TYPE, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS + 'Management/AccessMethodType/CreateAccessMethodType',
        accessMethodType,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.accessMethodTypesList.unshift(res.data.data)

        commit(AccessMethodTypeMutations.SET_ACCESS_METHOD_TYPES_LIST, rootGetters.accessMethodTypesList)
        commit(AccessMethodTypeMutations.SET_REQUEST_STATE_ACCESS_METHOD_TYPE, res)
        commit(AccessMethodTypeMutations.SET_IS_CREATING_ACCESS_METHOD_TYPE, false)
        resolve(res.data.data)
      })
      .catch(err => {
        commit(AccessMethodTypeMutations.SET_REQUEST_STATE_ACCESS_METHOD_TYPE, err)
        commit(AccessMethodTypeMutations.SET_IS_CREATING_ACCESS_METHOD_TYPE, false)
        reject(err)
      })
    })
  },
  /**
   * Update an accessMethodType
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {AccessMethodTypeResponseDto} accessMethodType - AccessMethodTypeResponseDto
   */
  async updateAccessMethodType ({ commit, rootGetters }, accessMethodType: AccessMethodTypeResponseDto) {
    commit(AccessMethodTypeMutations.SET_IS_UPDATING_ACCESS_METHOD_TYPE, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/AccessMethodType/UpdateAccessMethodType`,
        {...accessMethodType},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.accessMethodTypesList.splice(
          rootGetters.accessMethodTypesList.findIndex(elem => elem.id == accessMethodType.id),
          1,
          accessMethodType
        )
        commit(AccessMethodTypeMutations.SET_ACCESS_METHOD_TYPES_LIST, rootGetters.accessMethodTypesList)
        commit(AccessMethodTypeMutations.SET_REQUEST_STATE_ACCESS_METHOD_TYPE, res)
        commit(AccessMethodTypeMutations.SET_IS_UPDATING_ACCESS_METHOD_TYPE, false)
      })
      .catch(err => {
        commit(AccessMethodTypeMutations.SET_REQUEST_STATE_ACCESS_METHOD_TYPE, err)
        commit(AccessMethodTypeMutations.SET_IS_UPDATING_ACCESS_METHOD_TYPE, false)
      })
  },

  /**
   * It deletes an accessMethodType from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} accessMethodTypeId - string
   */
  async deleteAccessMethodType ({ commit, rootGetters }, accessMethodTypeId: string) {
    commit(AccessMethodTypeMutations.SET_IS_DELETING_ACCESS_METHOD_TYPE, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Management/AccessMethodType/DeleteAccessMethodType`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          accessMethodTypeId: accessMethodTypeId
        }
      })
      .then(res => {
        rootGetters.accessMethodTypesListArchived.splice(
          rootGetters.accessMethodTypesListArchived.findIndex(elem => elem.id == accessMethodTypeId),
          1
        )
        commit(AccessMethodTypeMutations.SET_ACCESS_METHOD_TYPES_LIST, rootGetters.accessMethodTypesList)
        commit(AccessMethodTypeMutations.SET_REQUEST_STATE_ACCESS_METHOD_TYPE, res)
        commit(AccessMethodTypeMutations.SET_IS_DELETING_ACCESS_METHOD_TYPE, false)
      })
      .catch(err => {
        commit(AccessMethodTypeMutations.SET_REQUEST_STATE_ACCESS_METHOD_TYPE, err)
        commit(AccessMethodTypeMutations.SET_IS_DELETING_ACCESS_METHOD_TYPE, false)
      })
  },

  async archiveAccessMethodType (
    { commit, rootGetters },
    accessMethodTypeId: string
  ): Promise<void> {
    commit(AccessMethodTypeMutations.SET_IS_ARCHIVING_ACCESS_METHOD_TYPE, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Management/AccessMethodType/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[accessMethodTypeId]
        }
      )
      .then(res => {
        rootGetters.accessMethodTypesListArchived.unshift(rootGetters.accessMethodTypesList.find(elem => elem.id == accessMethodTypeId ))
        rootGetters.accessMethodTypesList.splice(
          rootGetters.accessMethodTypesList.findIndex(
            elem => elem.id == accessMethodTypeId
          ),
          1
        )
        commit(
          AccessMethodTypeMutations.SET_ACCESS_METHOD_TYPES_LIST,
          rootGetters.accessMethodTypesList
        )
        commit(AccessMethodTypeMutations.SET_REQUEST_STATE_ACCESS_METHOD_TYPE, res)
        commit(AccessMethodTypeMutations.SET_IS_ARCHIVING_ACCESS_METHOD_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(AccessMethodTypeMutations.SET_REQUEST_STATE_ACCESS_METHOD_TYPE, err)
        commit(AccessMethodTypeMutations.SET_IS_ARCHIVING_ACCESS_METHOD_TYPE, false)
      })
   },

   async restoreAccessMethodType (
    { commit, rootGetters },
    accessMethodTypeId: string
  ): Promise<void> {
    commit(AccessMethodTypeMutations.SET_IS_UPDATING_ACCESS_METHOD_TYPE, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Management/AccessMethodType/Restore',
        [accessMethodTypeId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.accessMethodTypesList.unshift(rootGetters.accessMethodTypesListArchived.find(elem => elem.id == accessMethodTypeId ))
        rootGetters.accessMethodTypesListArchived.splice(
          rootGetters.accessMethodTypesListArchived.findIndex(
            elem => elem.id == accessMethodTypeId
          ),
          1
        )
        commit(AccessMethodTypeMutations.SET_REQUEST_STATE_ACCESS_METHOD_TYPE, res)
        commit(AccessMethodTypeMutations.SET_IS_UPDATING_ACCESS_METHOD_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(AccessMethodTypeMutations.SET_REQUEST_STATE_ACCESS_METHOD_TYPE, err)
        commit(AccessMethodTypeMutations.SET_IS_UPDATING_ACCESS_METHOD_TYPE, false)
      })
   }
}
