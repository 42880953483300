import { MutationTree } from "vuex";
import { IFilterState } from "./states";
import { IFilters } from "./iFilters"

export enum FilterMutations {
    SET_INTERVENTION_FILTERS = "SET_INTERVENTION_FILTERS"
}

export const mutations: MutationTree<IFilterState> = {
    [FilterMutations.SET_INTERVENTION_FILTERS]: (state, payload: IFilters) => {
        state.interventionFilters = payload;
    },
}