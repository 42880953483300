import { IInterventionStatisticsState } from "./states";
import { MutationTree } from "vuex";

export enum InterventionStatisticsMutations {
  SET_STATS_INTERVENTIONS = "SET_STATS_INTERVENTIONS",

  // COMON
  SET_IS_LOADING_STATS_INTERVENTIONS = "SET_IS_LOADING_STATS_INTERVENTIONS",
}

export const mutations: MutationTree<IInterventionStatisticsState> = {
  [InterventionStatisticsMutations.SET_STATS_INTERVENTIONS]: (
    state,
    payload: any
  ) => {
    state.statsInterventions = payload;
  },

  //COMMON
  [InterventionStatisticsMutations.SET_IS_LOADING_STATS_INTERVENTIONS]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingStatsInterventions = payload;
  },
};
