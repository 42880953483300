import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { PoolResponseDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../RootState'
import { IPoolState } from './states'


export const getters: GetterTree<IPoolState, RootState> = {
  poolsList: (state): PoolResponseDto[] => state.poolsList,
  poolsListArchived: (state): PoolResponseDto[] => state.poolsListArchived,
  pool     : (state): PoolResponseDto => state.pool,

  // COMMON
  isLoadingPoolsList   : (state): boolean => state.isLoadingPoolsList,
  isLoadingPoolsListArchived   : (state): boolean => state.isLoadingPoolsListArchived,
  isLoadingPool   : (state): boolean => state.isLoadingPool,
  isCreatingPool  : (state): boolean => state.isCreatingPool,
  isUpdatingPool  : (state): boolean => state.isUpdatingPool,
  isDeletingPool  : (state): boolean => state.isDeletingPool,
  requestStatePool: (state): IRequestState => state.requestStatePool,
  isOpenSidebarPool : (state): boolean => state.isOpenSidebarPool,
  isArchivingPool : (state): boolean => state.isArchivingPool,
}